export default {
  common: {
    title: 'TF Dashboard',
    logout: 'Logout',
    welcome_system: 'Welcome to Admin System',
    changePassword: 'Change Password',
    status: {
      OPEN: 'Open',
      CLOSE: 'Close',
      TEST: 'Test',
    },
  },
  affiliate: {
    commissionModel: {
      NORMAL: 'Nomal',
      SIMPLE: 'Simple',
      DETAILS: 'Details',
    },
    level: {
      JUNIOR_AFFILIATE: 'Junior Affiliate',
      SUB_AFFILIATE: 'Sub Affiliate',
      AFFILIATE: 'Affiliate',
      SUPER_AFFILIATE: 'Super Affiliate',
      MASTER_AFFILIATE: 'Master Affiliate',
      CHIEF_AFFILIATE: 'Chief Affiliate',
    },
    timeType: {
      MONTHLY: 'Monthly',
      WEEKLY: 'Weekly',
    },
    status: {
      APPLY: 'Apply',
      NORMAL: 'Normal',
      DISABLE: 'Disable',
    },
    belongType: {
      OFFICIAL: 'Official',
      PACKAGE: 'Package',
    },
  },
  types: {
    NORMAL: 'NORMAL',
    TEMPORARY: 'TEMPORARY',
    PERMANENT: 'PERMANENT',
    gameViolation: 'Game Violation',
    memberRequest: 'Member Request',
    others: 'Others',
    TEST: 'TEST',
    PROMO_TEST: 'PROMO TEST',
    AFFILIATE: 'AFFILIATE',
    OUTSIDE: 'OUTSIDE',
  },
  reportName: {
    Aff_Month_Report: 'Aff_Month_Report',
    Deposit_Record: 'Deposit_Record',
    Withdraw_Record: 'Withdraw_Record',
    Privilege_Record: 'Privilege_Record',
    Finance_Report: 'Finance_Report',
    Summary_Record: 'Summary_Record',
    Summary_Active_Record: 'Summary_Active_Record',
    Summary_Register_Record: 'Summary_Register_Record',
    Summary_Fdp_Record: 'Summary_Fdp_Record',
    Summary_Withdraw_Record: 'Summary_Withdraw_Record',
    Summary_Deposit_Record: 'Summary_Deposit_Record',
    Summary_Member_Record: 'Summary_Member_Record',
    Member_Bet_Record: 'Member_Bet_Record',
    Member_Bet_Money_Change: 'Member_Bet_Money_Change',
    Daily_Record: 'Daily_Record',
    Platform_Game_Record: 'Platform_Game_Record',
  },
  dateType: {
    depositDate: 'Deposit Date',
    finishDate: 'Finish Date',
    withdrawDate: 'Withdraw Date',
    paymentDate: 'Payment Date',
    regTime: 'Register Time',
    recordTime: 'Record Time',
  },
  depositStatus: {
    SUCCESS: 'Success',
    SUPPLEMENT_SUCCESS: 'Supplement Success',
    CLOSED: 'Closed',
    PENDING: 'Pending',
  },
  withdrawStatus: {
    ALL: 'All',
    SUCCESS: 'Success',
    FAIL: 'Fail',
    APPLY: 'Applying',
    STEP_1: 'Under Review',
    STEP_2: 'To Be Paid',
    STEP_3: 'Payment On Going',
    AUTOPAY: 'Automatic Payment',
    PENDING: 'Suspend',
    REJECT: 'Reject',
    SENDING: 'Sending',
    WAITING_CALLBACK: 'Waiting Callback',
    PAYING: 'Paying',
    WAITING_AUTO_PAY: 'Waiting Auto Payment',
    FAIL_REVIEW: 'Fail Review',
    WAITING_RETRY: 'Waiting Retry',
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  withdrawReviewType: {
    MANUAL: 'Manual Review',
    AUTO: 'Auto Review',
  },
  withdrawRuleType: {
    min: 'minimum ',
    max: 'maximum ',
    withdrawAmount: 'withdraw amount',
    balanceBeforeWithdrawal: 'balance before withdrawal',
    balanceAfterWithdrawal: 'balance after withdrawal',
    vip: 'vip',
    financialLevel: 'financial level',
    todayWithdrawCount: 'Today withdraw count',
    profit: 'profit',
  },
  outboxStatus: {
    0: 'Not reply yet',
    1: 'Has been replied',
  },
  editCheckedStatus: {
    0: '未审核',
    1: '已更改',
    2: '审核失败',
  },
  transfer: {
    status: {
      sending: 'SENDING',
      success: 'SUCCESS',
      fail: 'FAIL',
    },
    type: {
      deposit: 'DEPOSIT',
      withdraw: 'WITHDRAW',
    },
  },
  moneyChange: {
    type: {
      DEPOSIT: 'DEPOSIT',
      WITHDRAW: 'WITHDRAW',
      PROMO: 'PROMO',
      TRANSFER: 'TRANSFER',
      BET: 'BET',
      WITHDRAW_FAIL: 'WITHDRAW FAIL',
      ADJUST: 'ADJUST',
      PAYOUT: 'PAYOUT',
      ROLLBACK: 'ROLLBACK',
      PATCH: 'PATCH',
      BET_N_PAYOUT: 'BET AND PAYOUT',
      AFFILIATE_TRANSFER: 'AFFILIATE TRANSFER',
      VIP_REBATE: 'VIP REBATE',
      AFFILIATE_BET_REBATE: 'AFFILIATE BET REBATE',
    },
    subType: {
      DEPOSIT: 'Wallet to Game',
      WITHDRAW: 'Game to Wallet',
      TRASNFER_IN: 'Transfer In',
      TRANSFER_OUT: 'Transfer Out',
      AFFILIATE_SETTLEMENT: 'Affiliate Settlement',
      AFFILIATE_DEPOSIT: 'Transfer from Balance',
      AFFILIATE_COMMISSION: 'Transfer from Commission Balance',
    },
  },
  status: {
    monthSummary: {
      UNCLEAR: 'Unclear',
      CLEARING: 'Clearing',
      CLEARED: 'Cleared',
    },
    settlement: {
      CHECKING: 'Checking',
      PAY: 'Pay',
      CLEARED: 'Cleared',
    },
    member: {
      NORMAL: 'Normal',
      FROZEN: 'Frozen',
    },
    gameMatch: {
      ACTIVE: 'Active',
      ENDED: 'Ended',
    },
    gameMatchRecord: {
      PENDING_MATCH: 'Pending Match End',
      PENDING_SETTLE: 'Pending Settle',
      SETTLED: 'Settled',
      CANCEL: 'Cancel',
    },
    gameQuiz: {
      ONGOING: 'Ongoing',
      CANCEL: 'Cancel',
      ENDED: 'Ended',
    },
    gameQuizAnswer: {
      PENDING: 'Pending',
      CANCEL: 'Cancel',
      WIN: 'Win',
      LOSE: 'Lose',
    },
    gift: {
      OPEN: 'Open',
      CLOSE: 'Close',
    },
    giftRecord: {
      PENDING: 'Pending',
      PROCESSING: 'Processing',
      COMPLETE: 'Complete',
      EXPIRED: 'Expired',
      FAILED: 'Failed',
    },
    piggyBank: {
      SAVING: 'Saving',
      CLAIMED: 'Claimed',
    },
    uefaMatch: {
      PENDING: 'Pending',
      ONGOING: 'Ongoing',
      CANCEL: 'Cancel',
      ENDED: 'Ended',
    },
    uefaMatchRecord: {
      PENDING: 'Pending',
      LOSE: 'Lose',
      WIN_MATCH: 'Match Win',
      WIN_TEAM: 'Team Win',
      WIN_ALL: 'All Win',
      CANCEL: 'Cancel',
    },
    sportMatch: {
      ACTIVE: 'Active',
      ENDED: 'Ended',
      SETTLED: 'Settled',
    },
  },
  betStatus: {
    BET: 'BET',
    UNSETTLED: 'UNSETTLED',
    SETTLED: 'SETTLED',
    CANCEL: 'CANCEL',
    GIVEREWARD: 'TIPS',
  },
  giftType: {
    ENTITY: 'Entity',
    VIRTUAL: 'Virtual',
  },
  dashboard: {
    memberCount: 'Member Count',
    totalFirstDeposit: 'Total First Deposit',
    totalDeposit: 'Total Deposit',
    totalTransaction: 'No. of Transaction',
    totalTransfer: 'Total Transfer',
    totalWithdraw: 'Total Withdraw',
    totalWinLoss: 'Total Win/Loss',
    totalValidBet: 'Total Valid Bet',
    totalBonus: 'Total Bonus',
    totalProfit: 'Total Profit',
    returnAmount: 'Return Amount',
    totalActiveMember: 'Total Active Member',
    newRegisterMember: 'New Register Member',
    totalVisit: 'Total Visitor',
    depositChartName: 'Total Deposit Amount',
    depositAmount: 'Deposit Amount',
    depositMemberCount: 'No. of Member Deposit',
    depositTransactionCount: 'No. of Deposit Transaction',
    depositTransactionNumChartName:
      'Number of Member Deposit /Number of Deposit Transaction',
    financialLevelRatio: 'Financial Level Ratio',
    vipLevelRatio: 'VIP Level Ratio',
    paymentMethod: 'Payment Method',
    ratio: 'Ratio',
    fundAmount: 'Funds',
    paymentMethodSummary: 'Payment Methods Deposit Summary',
    total: 'Total',
    bet: 'Bet',
    payout: 'Payout',
    merchantDepositSummary: 'Merchant Deposit Summary',
    onlineStatsCompareChartName: 'Online Stats Compared By Day',
    onlineStatsChartName: 'Online Stats',
    rfdRegisterCount: 'Member Register Count',
    rfdFDepositCount: 'Member First Deposit Count',
    rfdReport: 'Register and Ftd Report',
  },
  reportGame: {
    gamePlatform: 'Game Platform',
    gameMemberCount: 'Daily Bet Member Count',
    gameBetCount: 'Daily Bet Transaction Count',
    gameBetAmount: 'Daily Bet Amount',
    gameWinLoss: 'Daily Win/Loss Amount',
    gameMemberCountTotal: 'Total Bet Member Count',
    gameBetCountTotal: 'Total Bet Transaction Count',
    gameBetAmountTotal: 'Total Bet Amount',
    gamePayoutAmountTotal: 'Total Payout Amount',
    gameWinLossTotal: 'Total Win/Loss Amount',
  },
  gameType: {
    ALL: 'ALL',
    SLOT: 'SLOT',
    LIVE: 'LIVE',
    FISH: 'FISH',
    SPORT: 'SPORT',
    ESPORT: 'ESPORT',
    POKER: 'POKER',
    LOTTERY: 'LOTTERY',
    CASUAL: 'CASUAL',
    NBA: 'NBA',
    COCKFIGHT: 'COCKFIGHT',
    MSI: 'MSI',
    COPA: 'COPA AMERICA',
    UEFA: 'UEFA EUROPA',
    UCL: 'UEFA CHAMPIONS LEAGUE'
  },
  homeBannerType: {
    HOME: 'Home',
    LIVE: 'Live',
    SLOT: 'Slot',
    FISH: 'Fish',
    POKER: 'Poker',
    PROMO: 'Promo',
    HOMEPROMO: 'Home Promo',
    HOMEPOP: 'Home Pop',
    CENTERPROMO: 'Center Promo',
    LOGIN: 'Login',
    REGISTER: 'Register',
    VIP: 'VIP',
  },
  distributeStatus: {
    PENDING: 'Pending',
    IN_PROGRESS: 'Distributing',
    DISTRIBUTED: 'Distributed',
    CANCEL: 'Cancel',
    PRE_PROCESS: 'Distributing',
  },
  referFriendEvent: {
    INFO: 'Complete Info',
    FIRST: 'First Deposit',
    SECOND: 'Second Deposit',
  },
  rollover: {
    recordStatus: {
      ONGOING: 'Ongoing',
      COMPLETED: 'Completed',
      VOID: 'Void',
      CANCEL: 'Cancel',
    },
  },
  rebateLevel: {
    LEVEL_0: 'Level 0',
    LEVEL_1: 'Level 1',
    LEVEL_2: 'Level 2',
    LEVEL_3: 'Level 3',
    LEVEL_4: 'Level 4',
    LEVEL_5: 'Level 5',
    LEVEL_6: 'Level 6',
    LEVEL_7: 'Level 7',
    LEVEL_8: 'Level 8',
    LEVEL_9: 'Level 9',
    LEVEL_10: 'Level 10',
    LEVEL_11: 'Level 11',
    LEVEL_12: 'Level 12',
    LEVEL_13: 'Level 13',
  },
  gameQuiz: {
    questions: {
      1: 'Which team won?',
      2: 'Handicap (Including Overtime)',
      3: 'Total Number of Kills in Total Rounds (Including Overtime)',
      4: 'Total rounds/kills for both teams',
      5: 'Kill the highest team?',
      6: 'Which team first blood',
    },
    answers: {
      CSGO: {
        handicapAbove: 'Team Difference 4.5 Or Above',
        handicapBelow: 'Team Difference 4.5 Or Below',
        killBig: 'Total Rounds Big 21.5',
        killSmall: 'Total Rounds Small 21.5',
        killSignal: 'Signal',
        killDouble: 'Double',
      },
      LOL: {
        handicapAbove: 'Team Difference 4.5 Or Above',
        handicapBelow: 'Team Difference 4.5 Or Below',
        killBig: 'Total Kills Big 25.5',
        killSmall: 'Total Kills Small 25.5',
        killSignal: 'Signal',
        killDouble: 'Double',
      },
      DOTA2: {
        handicapAbove: '-10.5 Or Above',
        handicapBelow: '+10.5 Or Below',
        killBig: 'Total Kills Big 49.5',
        killSmall: 'Total Kills Small 49.5',
        killSignal: 'Signal',
        killDouble: 'Double',
      },
    },
  },
  promoLabel: {
    new: 'New',
    hot: 'Hot',
    normal: 'Normal',
    recommend: 'Recomend',
    daily: 'Daily',
    newbie: 'Newbie',
    limit: 'Limit',
    featured: 'Featured',
  },
  messageType: {
    NOTIFICATION: 'Notification',
    ACTIVITY: 'Activity',
    ANNOUNCEMENT: 'Announcement',
    MATCH: 'Match',
    PAYMENT: 'Payment',
  },
  sitePlatform: {
    followType: 'พิมพ์',
    follow: 'ก่อนที่จะติดตาม',
    new: 'อัพเดตประเภทเกม',
  },
  sortType: {
    ASC: 'Ascending',
    DESC: 'Descending',
  },
  promoType: {
    WELCOME: 'WELCOME',
    SPORT: 'SPORT',
    ESPORT: 'ESPORT',
    FISH: 'FISH',
    LIVECASINO: 'LIVE CASINO',
    SLOTGAME: 'SLOT GAME',
    POKER: 'POKER',
    DAILY: 'DAILY',
    FTD: 'FTD',
    LOTTERY: 'LOTTERY',
    OTHER: 'OTHER',
    LIMITED: 'LIMITED',
    SLOTWELCOME: 'Slot - Welcome',
    SLOTDAILY: 'Slot - Daily Weekly',
    SLOTOTHER: 'Slot - Rebate & Other',
  },
  scheduleType: {
    NORMAL: 'Normal',
    BET_RECORDS: 'Bet Records',
  },
  summonFailReason: {
    PRIVI_IS_CLOSED: 'Privilege is closed',
    PRIVI_HAS_ENDED: 'Privilge has ended',
    PRIVI_HAVENT_START: 'Privilege havent start',
    SUMMONER_VIP_LEVEL_NOT_REACH: 'Summoner VIP level not reach',
    MEMBER_VIP_LEVEL_EXCEED_1: 'Member VIP level exceed 1',
    MEMBER_HISTORY_DEPOSIT_AMOUNT_NOT_REACH:
      'Member history deposit amount not reach',
    MEMBER_DEPOSIT_IN_PAST_15_DAYS: 'Member has deposit recently',
    MEMBER_BET_IN_PAST_15_DAYS: 'Member has bet recently',
    MEMBER_AND_SUMMONER_SAME_REGISTER_DETAIL:
      'Member and summoner same register detail',
    MEMBER_ALREADY_SUMMONED: 'Member is already summoned',
  },
  lottery: {
    status: {
      BET: 'BET',
      WIN: 'WIN',
      LOSS: 'LOSS',
    },
    prizeLevel: {
      FIRST_PRIZE: 'FIRST PRIZE',
      GROUP_THREE: 'GROUP THREE',
      GROUP_SIX: 'GROUP SIX',
      TWO_D: '2D',
    },
  },
  memberReferStatus: {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
  },
  memberReferFailReason: {
    PRIVI_IS_CLOSED: 'Privilege is closed',
    PRIVI_HAS_ENDED: 'Privilge has ended',
    VIP_LEVEL_IS_NOT_ALLOWED: 'VIP level is not allowed',
    AFFILIATE_IS_NOT_ALLOWED: 'Affiliate is not allowed',
    ERROR_MEMBER_PHONE_NOT_VERIFIED: 'Phone not verified',
    ERROR_REGISTER_DUPLICATE_IP_OR_SID: 'Duplicate IP or Device',
    ERROR_DEPOSIT_AMT_MIN_NOT_MEET: 'Minimum Deposit Amount not reach',
    ERROR_BET_AMT_MIN_NOT_MEET: 'Minimum Bet Amount not reach',
    ERROR_SIMILAR_IP_DETECTED: 'Similar IP Detected',
    ERROR_SIMILAR_SID_DETECTED: 'Similar Device Detected',
  },
  rewardType: {
    BET: 'BET',
    DEPOSIT: 'DEPOSIT',
    ONE_TIME: 'ONE TIME',
  },
  smsSend: {
    template1: 'Welcome New Member',
    template2: 'Deposit Promotion',
    template3: 'Welcome Back',
  },
  displayLocation: {
    HOMEPAGE: 'HOMEPAGE',
    PROMO_PAGE: 'PROMO PAGE',
    DEPOSIT_PAGE: 'DEPOSIT PAGE',
    WITHDRAW_PAGE: 'WITHDRAW PAGE',
    PERSONAL_CENTRE: 'PERSONAL CENTRE',
  },
  mode: {
    DYNAMIC: 'DYNAMIC',
    STATIC: 'STATIC',
  },
  autoplayStatus: {
    ON: 'ON',
    OFF: 'OFF',
  },
  valueRulesType: {
    INPUT: 'INPUT',
    RADIO: 'RADIO',
    SELECT: 'SELECT',
    SWITCH: 'SWITCH',
    CHECKBOX: 'CHECKBOX',
  },
  withdrawType: {
    Manual: 'Manual',
    AUTO_WITHDRAW: 'Auto Withdraw',
  },
  siteType: {
    main: 'MAIN',
    slot: 'SLOT',
  },
  language: {
    en: 'English',
    zh: 'Chinese',
    th: 'Thai',
    kr: 'Korean',
    vn: 'Vietnamese'
  },
  fields: {
    account: 'Account',
    accountHolder: 'Account Holder',
    accountInfo: 'Account Information',
    accountNumber: 'Account Number',
    accountStatus: 'Account Status',
    accurate: 'Accurate Search',
    action: 'Action',
    actionType: 'Action Type',
    activeMember: 'Active Member',
    activeMemberCount: 'Active Member Count',
    activePlayer: 'Active Player',
    activeUser: 'Active User',
    actualRate: 'Actual Rate',
    add: 'Add',
    addAds: 'Add Ads Popout',
    addAdjustmentReason: 'Add Adjustment Reason',
    addAffiliate: 'Add Affiliate',
    addAffiliateCommission: 'Add Affiliate Commission',
    addAffiliateDomain: 'Add Affiliate Domain',
    addAffiliateAnnouncement: 'Add Affiliate Announcement',
    addAffiliateAnnouncementType: 'Add Affiliate Announcement Type',
    addAffiliateFinancialConfig: 'Add Affiliate Financial Config',
    addAnnouncement: 'Add Announcement',
    addAnnouncementType: 'Add Announcement Type',
    addBank: 'Add Bank',
    addBankCard: 'Add Bank Card',
    addBankToPayment: 'Add Bank To Payment',
    addBankToWithdrawPlatform: 'Add Bank To Withdraw Platform',
    addBanner: 'Add Banner',
    addBbDacha: 'Add BB Dacha',
    addRewardGroup: 'Add Reward Group',
    addCurrency: 'Add Currency',
    addCurrencyRate: 'Add Currency Rate',
    addDepositSetting: 'Add Deposit Setting',
    addDomain: 'Add Domain',
    addEmail: 'Add Email',
    addFinancialLevel: 'Add Financial Level',
    addGame: 'Add Game',
    addGameMatch: 'Add Game Match',
    addGameQuiz: 'Add Game Quiz',
    addGift: 'Add Gift',
    addIpLabel: 'Add IP Label',
    additionalTicketDetail: 'Additional Ticket Detail',
    additionalKey: 'Additional Key',
    addJob: 'Add Job',
    addLimit: 'add Limit',
    addLotteryResult: 'Add Lottery Result',
    addMember: 'Add Member',
    addMemberAmountAdjust: 'Add Member Amount Adjust',
    addMemberPrivilegeBlacklist: 'Add Member Privilege Blacklist',
    addMemberRebateRule: 'Add Member Rebate Rule',
    addMenu: 'Add Menu',
    addNodes: 'Add Nodes',
    addPayType: 'Add Pay Type',
    addPlatform: 'Add Platform',
    addPlatformAccount: 'Add Platform Account',
    addPrivilegeInfo: 'Add Privilege Info',
    addPromoType: 'Add Promo Type',
    addQuestionnaire: 'Add Questionnaire',
    addRecipient: 'Add Recipient',
    addRedirect: 'Add Redirect',
    addRemark: 'Add Remark',
    addRiskLevel: 'Add Risk Level',
    addRole: 'Add Role',
    addSettings: 'Add Settings',
    addSite: 'Add Site',
    addSitePlatform: 'Add Site Platform',
    addStage: 'Add Stage',
    addSystemMessage: 'Add System Message',
    addSyncDefault: 'Sync Competition List Within 3 Days',
    addTelephone: 'Add Telephone Number',
    addTicket: 'Add Ticket',
    addUser: 'Add User',
    addVip: 'Add VIP',
    addVipRebateRule: 'Add VIP Rebate Rule',
    addWithdrawPlatform: 'Add Withdraw Platform',
    addWithdrawSetting: 'Add Withdraw Setting',
    addWithdrawalBankBlacklist: 'Add Withdrawal Bank Blacklist',
    address: 'Address',
    adjustAmount: 'Adjust Amount',
    adjustBetAmount: 'Adjust Bet Amount',
    adjustBy: 'Adjust By',
    adjustReason: 'Adjust Reason',
    adjustTime: 'Adjust Time',
    adjustType: 'Adjust Type',
    adjustmentReasonType: 'Adjust Reason Type',
    adjustmentReason: 'Adjust Reason',
    adjustment: 'Adjustment',
    adjustmentType: 'Adjustment Type',
    affiliate: 'Affiliate',
    affiliateBankCard: 'Affiliate Bank Card',
    affiliateBankCardRecord: 'Affiliate Bank Card Record',
    affiliateCode: 'Affiliate Code',
    channelName: 'Channel Name',
    affiliateDomainList: 'Affiliate Domain List',
    affiliateFinancialManagement: 'Affiliate Financial Management',
    affiliateFBRequestList: 'Affiliate Facebook Pixel List',
    affiliateFBRequestUrl: 'Affiliate URL',
    affiliateDepositChannel: 'Affiliate Deposit Channel',
    affiliateH5Link: 'Affiliate H5 Link',
    affiliateInfo: 'Affiliate Information',
    affiliateLevel: 'Affiliate Level',
    affiliateLink: 'Affiliate Link',
    affiliateList: 'Affiliate List',
    affiliateName: 'Affiliate Name',
    affiliateShortUrlPlatform: 'Affiliate Short Url Platform',
    affiliateStatus: 'Affiliate Status',
    affiliateTransferAmount: 'Transfer Amount',
    affiliateTransferRecord: 'Transfer Record',
    affiliateWallet: 'Transfer Wallet',
    affiliateWebLink: 'Affiliate Web Link',
    advancedSearch: 'Advanced Search',
    afterAmount: 'After Amount',
    afterBalance: 'After Balance',
    afterEdit: 'After Edit',
    afterVipLevel: 'After VIP Level',
    amount: 'Amount',
    amountLimitPerRain: 'Amount Limit Per Rain',
    adjust: 'Amount Adjust',
    alias: 'Alias',
    all: 'All',
    allBonus: 'All Bonus',
    allvip: 'All VIP',
    allfinanciallevel: 'All Financial Status',
    allprofit: 'All Profit',
    allreviewstatus: 'All Review Status',
    allrisklevel: 'All Risk Level',
    allmembers: 'All Members',
    allmembertype: 'All Member Type',
    announcement: 'Announcement',
    announcementType: 'Announcement Type',
    answer: 'Answer',
    answerOne: 'Question One Answer',
    answerTwo: 'Question Two Answer',
    answerThree: 'Question Three Answer',
    appVersion: 'App Version',
    applying: 'Applying',
    approve: 'Approve',
    approveBy: 'Approve By',
    assigned: 'Assigned',
    assignment: 'Assignment',
    attachment: 'Attachment',
    auditTime: 'Audit Time',
    automaticPayment: 'Automatic Payment',
    autopay: 'Auto Pay',
    autoplay: 'AutoPlay',
    automaticPay: 'AUTOMATIC_PAYMENT',
    autoWithdrawSetting: 'Automatic Withdraw Setting',
    autoWithdrawRule: 'Auto Withdraw Rule',
    awards: 'Awards',
    awayTeam: 'Away Team',
    awayTeamIcon: 'Away Team Icon',
    awayTeamResult: 'Away Team Result',
    back: 'Back',
    balance: 'Balance',
    balanceUrl: 'Balance URL',
    bank: 'Bank',
    bankAccount: 'Bank Account',
    bankCard: 'Bank Card',
    bankCardNumber: 'Bank Card Number',
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    bankNumber: 'Bank Number',
    bankType: 'Bank Type',
    batchCancel: 'Batch Cancel',
    bbDachaDetails: 'BB Dacha Details',
    bbdachaAnswers: 'BB Dacha Answers',
    bbdachaSetting: 'BB Dacha Setting',
    beanName: 'Bean Name',
    beforeAmount: 'Before Amount',
    beforeBalance: 'Before Balance',
    beforeEdit: 'Before Edit',
    beforeVipLevel: 'Before VIP Level',
    bet: 'Bet',
    betAmount: 'Bet Amount',
    betId: 'Bet ID',
    belongType: 'Belong Type',
    betMoneyChange: 'Member Bet Money Change',
    betMoreThan: 'Bet More Than',
    betRebateDetails: 'Bet Rebate Record Details',
    betRebateRecord: 'Bet Rebate Record',
    betRecords: 'Bet Records',
    betStatus: 'Bet Status',
    betTime: 'Bet Time',
    betTotal: 'Total Bet',
    betWheelSettings: 'Bet Wheel Settings',
    betWheelRecords: 'Bet Wheel Records',
    binded: 'Binded',
    birthday: 'Birthday',
    bindTypeAll: 'All',
    bindTypeNew: 'New',
    bindTypeRemove: 'Remove',
    blacklisted: 'Blacklisted',
    bonus: 'Bonus',
    bonusAmount: 'Bonus Amount',
    bonusDays: 'Bonus Days',
    bonusReceived: 'Bonus Received',
    bonusType: 'Bonus Type',
    byDepositDateDesc: 'Sort by Deposit Date DESC',
    byDepositDateAsc: 'Sort by Deposit Date ASC',
    byFinishDateDesc: 'Sort by Finish Date DESC',
    byFinishDateAsc: 'Sort by Finish Date ASC',
    byPaymentDateDesc: 'Sort by Payment Date DESC',
    byPaymentDateAsc: 'Sort by Payment Date ASC',
    byWithdrawDateDesc: 'Sort by Withdraw Date DESC',
    byWithdrawDateAsc: 'Sort by Withdraw Date ASC',
    byprofitasc: 'Sort by profit ASC',
    byprofitdesc: 'Sort by profit DESC',
    bydateasc: 'Sort by date ASC',
    bydatedesc: 'Sort by date DESC',
    bulkApprove: 'Bulk Approve',
    bulkWithdraw: 'Bulk Withdraw',
    callbackUrl: 'Callback URL',
    cancel: 'Cancel',
    cancelAll: 'Cancel All',
    cancelBbDacha: 'Cancel BB Dacha',
    cancelBySearch: 'Cancel By Query',
    cancelDeposit: 'Cancel Deposit',
    cancelGameMatch: 'Cancel Game Match',
    cancelGift: 'Cancel Gift Redemption',
    cancelMatch: 'Cancel Match',
    cancelQuiz: 'Cancel Quiz',
    cancelRemark: 'Cancel Remark',
    cancelRolloverRecord: 'Cancel Rollover Record',
    cancelAllRolloverRecord: 'Cancel All Rollover Record',
    cancelUpdate: 'Cancel Update',
    cancelType: 'Cancel Type',
    cardAccount: 'Card Account',
    cardAddress: 'Card Address',
    cardNumber: 'Card Number',
    cardTime: 'Action Time',
    cardTypeAll: 'All',
    cardTypeBank: 'Bank',
    cardTypeWallet: 'E-Wallet',
    cardTypeCrypto: 'Crypto',
    category: 'Category',
    cause: 'Cause',
    changeAffiliate: 'Change Affiliate',
    check: 'REVIEW',
    checkall: 'Check All',
    checkBy: 'Check By',
    checkDate: 'Check Date',
    checkExclusiveUrl: 'Check Exclusive Url',
    checkFail: 'REVIEW_FAIL',
    checkTime: 'Check Time',
    choice: 'Choices',
    choiceOne: 'Question One Choices',
    choiceTwo: 'Question Two Choices',
    choiceThree: 'Question Three Choices',
    claimTime: 'Claim Time',
    claimableRebate: 'Claimable Rebate',
    className: 'Class Name',
    clearingSum: 'Clearing Sum',
    clientType: 'Client Type',
    close: 'Close',
    code: 'Code',
    color: 'Color',
    commission: 'Commission',
    commissionBalance: 'Commission Balance',
    commissionModel: 'Commission Model',
    commissionPercent: 'Commission %',
    commissionRate: 'Commission Rate',
    commissionReport: 'Commission Report',
    commissionWallet: 'Commission Wallet',
    commitTime: 'Commit Time',
    companyProfit: 'Company Profit',
    configGroup: 'Config Group',
    componentName: 'Component Name',
    componentPath: 'Component Path',
    composeMessage: 'Compose Message',
    consumingTime: 'Consuming Time',
    confirm: 'Confirm',
    confirmAndImport: 'Confirm and Import',
    confirmAndSend: 'Confirm and Send',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    confirmPay: 'Confirm Pay',
    confirmStatus: 'Confirm Status',
    confirmBy: 'Confirm By',
    content: 'Content',
    contentList: 'Bullet List',
    convertRate: 'Convert Rate',
    copy: 'Copy',
    copyPayment: 'Copying payment',
    copyWithdrawPlatform: 'Copying Withdraw Platform',
    copyTo: 'Copy to',
    copyToOtherLevel: 'Copy to other level',
    country: 'Country',
    countryImage: 'Country Image',
    createBy: 'Create By',
    createTime: 'Create Time',
    createVote: 'Create Vote',
    cronExpression: 'Cron Expression',
    currency: 'Currency',
    currencyCode: 'Currency Code',
    currencyName: 'Currency Name',
    currencyRate: 'Currency Rate',
    currencyWallet: 'Currency Wallet',
    currentPassword: 'Current Password',
    currentPlace: 'Current Place',
    customerSupportAddress: 'Customer Support Address',
    dailyActiveMemberCount: 'Daily Active Members',
    dailyDeposit: 'Daily Deposit',
    dailyDepositAmount: 'Daily Deposit Amount',
    dailyMin: 'Daily Minimum Spin',
    dailyMinRegLimit: 'Daily Minimum Register Limit',
    dailyWithdraw: 'Daily Withdraw',
    dailyWithdrawAmount: 'Daily Withdraw Amount',
    dailyFailAmount: 'Daily Fail Amount',
    dailyFailDeposit: 'Daily Fail Deposit',
    dailyFirstDepositLimit: 'Daily First Deposit Limit',
    dailyMinFirstDepositLimit: 'Daily Minimum First Deposit Limit',
    dailyMaxFirstDepositLimit: 'Daily Maximum First Deposit Limit',
    dailyFirstDepositProbability: 'Daily First Deposit Probability',
    dailyMemberFailDeposit: 'Daily Member Fail Deposit',
    dailyFailWithdraw: 'Daily Fail Withdraw',
    dailyMax: 'Daily Max Spin',
    dailyMaxRegLimit: 'Daily Max Register Limit',
    dailyRegProbabilty: 'Daily Register Probability',
    dailyMemberFailWithdraw: 'Daily Member Fail Withdraw',
    dailyRainDuration: 'Rain Duration',
    dailyRefreshDuration: 'Refresh Duration',
    dailyRegLimit: 'Daily Register Limit',
    dailySuccessAmount: 'Daily Success Amount',
    dailySuccessDeposit: 'Daily Success Deposit',
    dailyMemberSuccessDeposit: 'Daily Member Success Deposit',
    dailySuccessWithdraw: 'Daily Success Withdraw',
    dailyMemberSuccessWithdraw: 'Daily Member Success Withdraw',
    dailySuccessPercentage: 'Daily Success Percentage',
    dailyTotalAmount: 'Daily Total Amount',
    dailyTotalRedeem: 'Daily Total Redeem',
    dayWithdrawCount: 'Daily Withdraw Count',
    day1: 'Day 1 Unlock',
    day2: 'Day 2 Unlock',
    day3: 'Day 3 Unlock',
    day4: 'Day 4 Unlock',
    day5: 'Day 5 Unlock',
    day6: 'Day 6 Unlock',
    date: 'Date',
    data: 'Data',
    dataBefore: 'Data Before',
    dataAfter: 'Data After',
    dateReceived: 'Date Received',
    dateType: 'Date Type',
    deduct: 'Deduct',
    deductMemberAmountAdjust: 'Deduct Member Amount Adjust',
    defaultBonus: 'Default Bonus',
    defaultFinancialLevel: 'Default Financial Level',
    defaultAgentFinancialLevel: 'Default Affiliate Financial Level',
    defaultRiskLevel: 'Default Risk Level',
    defaultAgentRiskLevel: 'Default Affiliate Risk Level',
    defaultVip: 'Default VIP',
    defaultAgentVip: 'Default Affiliate VIP',
    delete: 'Delete',
    runAtOnce: 'runAtOnce',
    deleteRole: 'Delete Role',
    deliver: 'Deliver',
    delivered: 'Delivered',
    deliverGift: 'Deliver Gift',
    deposit: 'Deposit',
    depositAmount: 'Deposit Amount',
    depositAverage: 'Deposit Averate',
    depositBettingAmount: 'Deposit/Betting Amount',
    depositCount: 'Deposit Count',
    depositDate: 'Deposit Date',
    deposited: 'Deposited',
    depositExchangeRate: 'Deposit Exchange Rate',
    depositMemberCount: 'Deposit Member Count',
    depositPerSpin: 'Deposit Per Spin',
    depositSerialNo: 'Deposit Serial Number',
    depositStatus: 'Deposit Status',
    depositWithdrawalProfit: 'Deposit Withdrawal Profit',
    describe: 'Describe',
    description: 'Description',
    desktopImage: 'Desktop Image',
    desktopImageDark: 'Desktop Image Dark',
    desktopBackgroundImage: 'Desktop Background Image',
    desktopBackgroundImageDark: 'Desktop Background Image Dark',
    desktopBanner: 'Desktop Banner',
    deskTopBannerDark: 'Desktop Banner Dark',
    details: 'Details',
    device: 'Device',
    dif: 'Deposit Difference',
    disable: 'Disable',
    disableAffiliate: 'Disable Affiliate',
    disableAll: 'Disable All',
    disableType: 'Disable Type',
    displayUrl: 'Display Url',
    distributed: 'Distributed',
    distributeBy: 'Distribute By',
    distributePrivilege: 'Distribute Privilege',
    distributeRebate: 'Distribute Rebate',
    distributeRefer: 'Distribute Refer Bonus',
    distributeTime: 'Distribute Time',
    domain: 'Domain',
    domainCount: 'Domain Count',
    domainList: 'Domain List',
    done: 'Done',
    doneBy: 'Done By',
    downgradeToApplying: 'DOWNGRADE_TO_APPLYING',
    downgradeToWaitPay: 'WAIT_FOR_PAYMENT',
    downlineAffiliate: 'Downline Affiliate',
    downlineAffiliatePaymentChannel: 'Downline Affiliate Payment Channel',
    downlineCommission: 'Downline Commission',
    downlineCommissionRate: 'Downline Commission Rate',
    downlineMember: 'Downline Member',
    downlineProfit: 'Downline Profit',
    downloadTemplate: 'Download Template',
    draw: 'Draw',
    edit: 'Edit',
    editAds: 'Edit Ads Popout',
    editAdjustmentReason: 'Edit Adjustment Reason',
    editAffiliateCommission: 'Edit Affiliate Commission',
    editAffiliateDomain: 'Edit Affiliate Domain',
    editAffiliateAnnouncement: 'Edit Affiliate Announcement',
    editAffiliateAnnouncementType: 'Edit Affiliate Announcement Type',
    editAffiliateFinancialConfig: 'Edit Affiliate Financial Config',
    editAmount: 'Edit Amount',
    editAnnouncement: 'Edit Announcement',
    editAnnouncementType: 'Edit Announcement Type',
    editBank: 'Edit Bank',
    editBankCard: 'Edit Bank Card',
    editBanner: 'Edit Banner',
    editBelongType: 'Edit Belong Type',
    editConfig: 'Edit Config',
    editCurrency: 'Edit Currency',
    editCurrencyRate: 'Edit Currency Rate',
    editDepositSetting: 'Edit Deposit Setting',
    editDetails: 'Edit Details',
    editEmail: 'Edit Email',
    editFinancialLevel: 'Edit Financial Level',
    editGame: 'Edit Game',
    editGameMatch: 'Edit Game Match',
    editGift: 'Edit Gift',
    editIpLabel: 'Edit IP Label',
    editJob: 'Edit Job',
    editMemberRebateRule: 'Edit Member Rebate Rule',
    editMenu: 'Edit Menu',
    editPayType: 'Edit Pay Type',
    editPlatform: 'Edit Platform',
    editPlatformAccount: 'Edit Platform Account',
    editPrivilegeInfo: 'Edit Privilege Info',
    editPromoType: 'Edit Promo Type',
    editQuestionnaire: 'Edit Questionnaire',
    editRedirect: 'Edit Redirect',
    editRemark: 'Edit Remark',
    editRewardGroup: 'Edit Reward Group',
    editRiskLevel: 'Edit Risk Level',
    editRole: 'Edit Role',
    editScore: 'Edit Score',
    editSettings: 'Edit Settings',
    editSite: 'Edit Site',
    editSitePlatform: 'Edit Site Platform',
    editStage: 'Edit Stage',
    editTelephone: 'Edit Telephone Number',
    editTimeType: 'Edit Time Type',
    editType: 'Edit Type',
    editUser: 'Edit User',
    editVip: 'Edit VIP',
    editVipRebateRule: 'Edit VIP Rebate Rule',
    editWithdrawPlatform: 'Edit Withdraw Platform',
    editWithdrawSetting: 'Edit Withdraw Setting',
    email: 'Email',
    endBbDacha: 'End BB Dacha',
    endDate: 'End Date',
    endMatch: 'End Match',
    endQuiz: 'End Quiz',
    endTime: 'End Time',
    estimateRate: 'Estimate Rate',
    event: 'Event',
    exchangeRate: 'Exchange Rate',
    exclusiveUrlWeb: 'Exclusive Url(Web)',
    exclusiveUrlWap: 'Exclusive Url(App)',
    exclusiveUrlApp: 'Exclusive Url',
    exclusiveUrlCashier: 'Exclusive Url',
    exclusiveUrl: 'Exclusive Url',
    exportToExcel: 'Export to Excel',
    expressCompany: 'Express Company',
    externalUrl: 'External Url',
    extraParam: 'Extra Param',
    fail: 'Fail',
    failReason: 'Fail Reason',
    fbid: 'Facebook Pixel ID',
    fbToken: 'Facebook Pixel Token',
    fdConvertRate: 'FTD ConvertRate',
    features: 'Features',
    fee: 'Fee',
    feedbackType: 'Feedback Type',
    feedbackTypeAll: 'All',
    feedbackTypeDeposit: 'Deposit',
    feedbackTypeWithdraw: 'Withdraw',
    feedbackStatus: 'Feedback Status',
    feedbackStatusAll: 'All',
    feedbackStatusPending: 'Pending',
    feedbackStatusSuccess: 'Success',
    feedbackTime: 'Feedback Time',
    feeRate: 'Fee Rate',
    subtractAmount: 'Subtract Amount',
    field: 'Field',
    finalSum: 'Final Sum',
    financialLevel: 'Financial Level',
    financeType: 'Type',
    financeRemark: 'Finance Remark',
    financeStatus: 'Status',
    financePhotos: 'Photos',
    finishDate: 'Finish Date',
    firstDeposit: 'First Deposit',
    firstTicket: 'First Ticket (Bet/Deposit)',
    firstDepositMemberCount: 'First Deposit Count',
    firstDepositMember: 'First Deposit Member',
    freeze: 'Freeze',
    freezeMember: 'Freeze Member',
    unfreezeMember: 'Unfreeze Member',
    freezeType: 'Freeze Type',
    frequency: 'Frequency',
    fromWallet: 'Adjust from main wallet',
    fromCommission: 'Adjust from commission',
    ftd: 'FTD',
    ftdAverage: 'FTD Average',
    ftdAmount: 'FTD Amount',
    ftdCount: 'FTD Count',
    ftdDownlineCount: 'Downline FTD Count',
    depositDownlineCount: 'Downline Deposit Count',
    regDownlineCount: 'Downline Register Count',
    ftdTime: 'FTD Time',
    ftdTxn: 'FTD Txn',
    fundingInfo: 'Funding Information',
    game: 'Game',
    gameAccountName: 'Game Account Name',
    gameCode: 'Game Code',
    domainEasy: 'Domain Url',
    videoShiPin: 'Video Url',
    CHARITY: "Charity Url",
    gameConsolidateReport: 'Game Consolidate Report',
    gameMatch: 'Game Match',
    gameMatchRecord: 'Game Match Record',
    gameName: 'Game Name',
    gameQuiz: 'Game Quiz',
    gameQuizAnswer: 'Game Quiz Answers',
    gameStepsRecords: 'Game Steps Records',
    gameStepsSettings: 'Game Steps Settings',
    gameType: 'Game Type',
    generateSummary: 'Generate Summary',
    goalAgainst: 'Goal Against',
    goalDifference: 'Goal Difference',
    goalScored: 'Goal Scored',
    groupId: 'Group ID',
    gift: 'Gift',
    giftCode: 'Gift Code',
    giftName: 'Gift Name',
    giftRecord: 'Gift Record',
    hasPromo: 'Has Promotion',
    hidden: 'Hidden',
    homeTeam: 'Home Team',
    homeTeamIcon: 'Home Team Icon',
    homeTeamResult: 'Home Team Result',
    icon: 'Icon',
    pIcon: 'Privilege Icon',
    id: 'ID',
    identifyCode: 'Identify Code',
    imageSize: 'Image Size',
    import: 'Import',
    inbox: 'Inbox',
    ineligible: 'Ineligible',
    innerSetting: 'Inner Wheel Setting',
    ip: 'Access IP',
    isDeposit: 'Show Deposit',
    isMultiple: 'Is Multiple Choice',
    itsYou: "it's you",
    job: 'Job',
    jobLog: 'Job Log',
    jobName: 'Job Name',
    jobType: 'Job Type',
    label: 'Label',
    labelCancel: 'cancel',
    language: 'Language',
    lastDepositTime: 'Last Deposit Time',
    lastDigit: 'Last Digit',
    lastDigitMinDayDeposit: 'Min Day Deposit Amount for Last Digit Red Packet',
    lastDigitRules: 'Last Digit Red Packet Rules',
    lastLogin: 'Last Login',
    lastLoginAddress: 'Last Login Address',
    lastLoginIp: 'Last Login IP',
    lastLoginTime: 'Last Login Time',
    lastMonth: 'Last Month',
    last3Months: 'Last 3 Months',
    last6Months: 'Last 6 Months',
    last5Min: 'Last 5 Minutes',
    last15Min: 'Last 15 Minutes',
    last30Min: 'Last 30 Minutes',
    last1Hour: 'Last 1 Hour',
    lastWeek: 'Last Week',
    latestFetchBetRecordTime: 'Latest Fetch Bet Record Time',
    level: 'Level',
    levelUpCredit: 'Level Up Credit',
    limitNumber: 'Limit Number',
    localCurrencyAmount: 'Local Currency Amount',
    lockStatus: 'Lock Status',
    login: 'Login',
    loginAddress: 'Login Address',
    loginFailedReason: 'Login Failed Reason',
    loginInfo: 'Login Information',
    loginIp: 'Login IP',
    loginName: 'Login Name',
    loginNameSeparateComma: 'Login Name (Separate by comma)',
    loginStatus: 'Login Status',
    loginTime: 'Login Time',
    loginUrl: 'Login URL',
    loginWay: 'Login Way',
    logoutPlayer: 'Logout Player',
    loss: 'Loss',
    lossAmount: 'Loss Amount',
    lossRebateRecord: 'Loss Rebate Record',
    lotteryRecordList: 'Lottery Record List',
    lotteryResultList: 'Lottery Result List',
    lowBalance: 'Minimum Balance',
    maintenanceEndTime: 'Maintenance End Time',
    maintenanceStartTime: 'Maintenance Start Time',
    maintenanceTime: 'Maintenance Time',
    mallCode: 'Mall Code',
    mallExtraKey: 'Mall Extra Key',
    mallKey: 'Mall Key',
    mallName: 'Mall Name',
    manualPay: 'Manual Pay',
    massImport: 'Mass Import',
    messageAffiliateDepositDisplay:
      'Please set deposit display setting for new afiliate',
    matchTime: 'Match Time',
    matchTitle: 'Match Title',
    matureTime: 'Mature Time',
    maxBonus: 'Maximum Bonus',
    maxDailyWithdraw: 'Maximum Daily Withdraw Amount',
    maxDailyWithdrawTimes: 'Maximum Times of Daily Withdrawal',
    maxDeposit: 'Maximum Deposit',
    maxMemberClaimCountPerRain: 'Max Member Claim Count Per Rain',
    maxMemberClaimCountPerDay: 'Max Member Claim Count Per Day',
    maxTicket: 'Maximum ticket',
    maxWithdrawAmount: 'Maximum Withdraw Amount',
    mediaDesc: 'Media Name',
    mediaUrl: 'Media Url',
    member: 'Member',
    memberBankCard: 'Member Bank Card',
    memberBankCardRecord: 'Member Bank Card Record',
    memberBetRecord: 'Member Bet Record',
    memberBetRecordByPlatform: 'Platform Bet Summary',
    memberCommission: 'Member Commission',
    memberConsolidateReport: 'Member Consolidate Report',
    memberDetailDeposit: 'Total Deposit Amount',
    memberDetailDepositCount: 'Total Deposit Count',
    memberDetailPrivilege: 'Total Privilege Amount',
    memberDetailWithdraw: 'Total Withdraw Amount',
    memberFeedbackType: 'Feedback Type',
    memberId: 'Member ID',
    memberInfo: 'Member Info',
    memberInviteLimit: 'Member Invite Limit',
    memberList: 'Member List',
    memberMessage: 'Member Message',
    membersMonthlyTotalDeposit: 'Member Current Month Total Deposit',
    memberName: 'Member Name',
    memberPlatformAction: 'Action（Manual Register/Delete）',
    memberPlatformUpdate: 'Sync Password',
    memberPrivilegeBlacklist: 'Member Privilege Blacklist',
    memberProfitDownlineProfitUnsettleCommission:
      'Member Profit / Downline Profit / Unsettle Commission',
    memberPrivilegeRecord: 'Member Privilege Record',
    memberReport: 'Member Report',
    memberType: 'Member Type',
    memberWithdrawLog: 'Member Withdraw Log',
    menuGame: 'Menu Game Sequence',
    menuIcon: 'Menu Icon',
    menuTitle: 'Menu Title',
    menuType: 'Menu Type',
    menuOnlineUser: 'Online User',
    message: 'Message',
    min: 'Min Profit',
    minAmount: 'Min Amount',
    max: 'Max Profit',
    maxAmount: 'Max Amount',
    minno: 'Please insert min',
    maxno: 'Please insert max',
    memberRemark: 'Member Remark',
    minActiveMemberCount: 'Minimum Active Member Count',
    minBalance: 'Minimum Balance',
    minBonus: 'Minimum Bonus',
    minDeposit: 'Minimum Deposit',
    minTotalCommission: 'Minimum Total Commission',
    minWithdrawAmount: 'Minimum Withdraw Amount',
    mobile: 'Mobile',
    mobileImage: 'Mobile Image',
    mobileImageDark: 'Mobile Image Dark',
    mobileBackgroundImage: 'Mobile Background Image',
    mobileBackgroundImageDark: 'Mobile Background Image Dark',
    mobileBanner: 'Mobile Banner',
    mobileBannerDark: 'Mobile Banner Dark',
    mobileMessage: 'Mobile Message',
    mobileWay: 'Mobile',
    mode: 'Mode',
    moneyChange: 'Money Change',
    month: 'Month',
    mqLogProcessWithTitle: 'MQ Title (SummaryDoris)',
    mqLogProcessTopic: 'Topic',
    mqLogProcessTimestamp: 'System Timestamp',
    mqLogTimestampWithTimezone: 'Site Timestamp',
    name: 'Name',
    nbaMatchPreseasonRecord: 'NBA Preseason Match Record',
    nbaMatchRecord: 'NBA Playoffs Match Record',
    nbaMatchPreseasonSetting: 'NBA Preseason Match Setting',
    nbaMatchSetting: 'NBA Playoffs Match Setting',
    needRegister: 'Need Register',
    needSpecify: 'Need Specify',
    netProfit: 'Net Profit',
    netPhone: 'Phone Call Config',
    newMember: 'New Member',
    newMemberCount: 'New Member Count',
    newPassword: 'New Password',
    nextActivationTime: 'Next Activation Time',
    nextGetBetEndTime: 'Next Get Bet End Time',
    nextGetBetIndex: 'Next Get Bet Index',
    nextGetBetPage: 'Next Get Bet Page',
    nextGetBetStartTime: 'Next Get Bet Start Time',
    nextLevel: 'Next Level',
    nextLevelPoint: 'Next Level Point',
    no: 'No',
    noData: 'No Data',
    noDeposit: 'No Deposit',
    noLimit: 'No Limit',
    noOfDeduction: 'Number of Deduction',
    noOfDepositTimes: 'Number of Deposit Times',
    noOfPrivilegeClaimed: 'Number of Privilege Claimed',
    noOfReimbursement: 'Number of Reimbursement',
    noOfWinners: 'No of Winners',
    noOfWithdrawTimes: 'Number of Withdraw Times',
    notBinded: 'Not Binded',
    notRegister: 'Not Register',
    notice: 'Notice',
    notRead: 'Not Read',
    notReplied: 'Not Replied',
    number: 'Number',
    oneTimeSpin: 'One Time Spin',
    onlyDeposit: 'Show Member With Deposit',
    onlyFake: 'Only show non Member Record',
    onlyReal: 'Only show Member Record',
    open: 'Open',
    operate: 'Operate',
    operator: 'Operator',
    operateTime: 'Operate Time',
    operationTime: 'Operation Time',
    operationType: 'Operation Type',
    orderBy: 'Order By',
    orderNo: 'Order No',
    orderTrackingNo: 'Order Tracking No',
    outerSetting: 'Outer Wheel Setting',
    param: 'Parameter',
    parentSite: 'Parent Site',
    password: 'Password',
    pause: 'Pause',
    pay: 'Pay',
    payBy: 'Pay By',
    payMessage: 'Pay Message',
    payMessageMobile: 'Pay Message Mobile',
    payResultType: 'Pay Result Type',
    payTime: 'Pay Time',
    payType: 'Pay Type',
    payTypeCode: 'Pay Type Code',
    payTypeName: 'Pay Type Name',
    payment: 'Payment',
    paymentArea: 'Payment Area',
    paymentBy: 'Payment By',
    paymentCard: 'Payment Card',
    paymentChannel: 'Payment Channel',
    paymentDate: 'Payment Date',
    paymentDisplay: 'Payment Display',
    paymentFee: 'Payment Fee',
    paymentMethod: 'Payment Method',
    paymentName: 'Payment Name',
    paymentOnGoing: 'Payment on going',
    paymentType: 'Payment Type',
    payout: 'Payout',
    payoutTotal: 'Total Payout',
    return_ratio: 'Ratio Return',
    return_ratio_total: 'Total Ratio Return',
    pcWay: 'PC',
    pending: 'SUSPEND',
    pendingTransaction: 'Pending',
    permission: 'Permission',
    permissionAssignment: 'Permission Assignment',
    pgroup: 'Privilege Group',
    photo: 'Photo',
    piggyBankRecords: 'Piggy Bank Records',
    piggyBankSettings: 'Piggy Bank Settings',
    place: 'Place',
    placeTime: 'placeTime',
    planDays: 'Plan Days',
    planOdds: 'Plan Odds',
    planLimitMin: 'Plan Min Limit',
    planLimitMax: 'Plan Max Limit',
    planTimeLimit: 'Purchase Limit',
    platform: 'Platform',
    platformDetails: 'Platform Details',
    platformAccount: 'Platform Account',
    platformId: 'Platform ID',
    platformBalance: 'Platform Balance',
    platformCode: 'Platform Code',
    platformFee: 'Platform Fee',
    platformFundTransfer: 'Platform Fund Transfer',
    platformMatchId: 'Platform Match ID',
    platformName: 'Platform Name',
    playerName: 'Player Name',
    pleaseChoose: 'Please choose',
    pleaseInput: 'Please input',
    points: 'Points',
    poolAmount: 'Pool Amount',
    previousLevel: 'Previous Level',
    previousLevelName: 'Previous Level Name',
    previousPlace: 'Previous Place',
    priority: 'Priority',
    privilege: 'Privilege',
    privilegeCode: 'Privilege Code',
    privilegeId: 'Privilege ID',
    privilegeName: 'Privilege Name',
    privilegeRedeemed: 'Privilege Redeemed',
    privilegeSerialNo: 'Privilege Serial Number',
    privilegeType: 'Privilege Type',
    probability: 'Probability',
    profit: 'Profit',
    profitpositive: 'Positive Profit',
    profitnegative: 'Negative Profit',
    promo: 'Promo',
    promoApplication: 'Promo Application',
    promoCode: 'Promo Code',
    promoPages: 'Promotion Pages',
    promoStyle: 'Promo Style',
    promoType: 'Promo Type',
    queryNumber: 'Query Number',
    queryOption: 'Query Option',
    queryRestriction: 'Query Restriction',
    question: 'Question',
    questionOne: 'Question One',
    questionTwo: 'Question Two',
    questionThree: 'Question Three',
    quizDetails: 'Quiz Details',
    quizTime: 'Quiz Time',
    quizTitle: 'Quiz Title',
    randomMember: 'Random Member',
    rate: 'Rate',
    realName: 'Real Name',
    englishName: 'English Name',
    realVirtualVotes: 'Real Votes + Virtual Votes',
    reason: 'Reason',
    reasonType: 'Reason Type',
    reasonTemplate: 'Reason Template',
    rebate: 'Rebate',
    rebateDistributeTime: 'Rebate Distribute Time',
    rebateLevel: 'Rebate Level',
    rebatePercentage: 'Rebate %',
    regEndDate: 'Register End Date',
    regStartDate: 'Register Start Date',
    regTime: 'Register Time',
    redPacketMinDayDeposit: 'Min Day Deposit Amount for Red Packet Rain',
    redPacketMinTotalDeposit: 'Min Total Deposit Amount for Red Packet Rain',
    redPacketMinTotalDepositDays: 'Days for Total Deposit Calculation',
    redPacketMinTotalDepositWeeks: 'Weeks for Total Deposit Calculation',
    redPacketMinDayBetAmount: 'Min Day Bet Amount for Red Packet Rain',
    rule: 'Rule',
    maxRebate: 'Max Rebate',
    maxBalance: 'Maximun Balance',
    nickName: 'Nickname',
    rebateAmount: 'Rebate Amount',
    receiveTime: 'Receive Time',
    receiveType: 'Receive Type',
    recipient: 'Recipient',
    recipientTelephone: 'Recipient Telephone',
    records: 'Records',
    recordDate: 'Record Date',
    recordTime: 'Record Time',
    redeemCash: 'Redeem Cash',
    redeemedBy: 'Redeemed By',
    redeemPoints: 'Redeem Points',
    redirect: 'Redirect',
    reenterPassword: 'Re-Enter Password',
    referrer: 'Referrer',
    referrerList: 'Referrer List',
    referFriendRebateDetails: 'Refer Friend Rebate Details',
    referFriendRebateRecords: 'Refer Friend Rebate Records',
    register: 'Register',
    registered: 'Registered',
    registerAddress: 'Register Address',
    registerCount: 'Register Count',
    registerDevice: 'Register Device',
    registerHost: 'Register Host',
    registerIp: 'Register IP',
    registerMember: 'Register Member',
    registerTime: 'Register Time',
    registrationDate: 'Registration Date',
    referredFriends: 'Referred Friends',
    referFriendRecord: 'Refer Friend Record',
    remark: 'Remark',
    remove: 'Remove',
    reply: 'Reply',
    replyTitle: 'Reply Title',
    replyContent: 'Reply Content',
    requestUrl: 'Request URL',
    requestParam: 'Request Parameter',
    responseBody: 'Response Body',
    resend: 'Resend',
    reset: 'Reset',
    resettleBbDacha: 'Resettle BB Dacha',
    resettleGameQuiz: 'Resettle Game Quiz',
    resettleMatch: 'Resettle Match',
    result: 'Result',
    resultDate: 'Result Date',
    resultNumber: 'Result Number',
    resultTime: 'Result Time',
    resume: 'RESUME',
    revenueShare: 'Revenue Share',
    review: 'Review',
    reviewby: 'Review By',
    reviewno: 'Not Review',
    reviewsuccess: 'Correct',
    reviewstatus: 'Review Status',
    reviewfail: 'Wrong',
    reviewRule: 'Review Rule',
    rewardGroup: 'Reward Group',
    rewardGroupName: 'Group Name',
    rewardGroupDownlineQuota: 'Downline Quota',
    riskControl: 'Risk Control',
    riskDepositLimit: 'Risk Deposit Limit',
    riskLevel: 'Risk Level',
    riskPaymentChannel: 'Risk Payment Channel',
    role: 'Role',
    roleList: 'Role List',
    roleName: 'Role Name',
    rollover: 'Rollover',
    rollOverAmt: 'Rollover Amount',
    rolloverType: 'Rollover Type',
    rollOverMulti: 'Rollover Multi Amount',
    rolloverAmount: 'Rollover Amount',
    rolloverRecord: 'Rollover Record',
    gameTypeRollover: 'Game Type Rollover',
    recommend: 'Recommend',
    router: 'Router',
    round: 'Free Round',
    rules: 'Rules',
    ruleParam: 'Rule Param',
    ruleType: 'Rule Type',
    run: 'Run',
    save: 'Save',
    saveAsNewPayment: 'Save as new payment',
    scheduleTime: 'Schedule Time',
    schemaName: 'Schema Name',
    score: 'Score',
    search: 'Search',
    searchCondition: 'Search Condition',
    securityQuestion: 'Security Question',
    secondTicket: 'Second Ticket （Bet/Deposit）',
    selectedMembers: 'Selected Members',
    sendTime: 'Send Time',
    sendSuccess: 'Send Success',
    sendFail: 'Send Fail',
    select: 'Select',
    selectNodeAddTo: 'Select nodes to add to ',
    selected: 'Selected',
    selectedNumber: 'Selected Number',
    selectedTeam: 'Selected Team',
    send: 'Send',
    separateByComma: 'Separate by comma',
    sequence: 'Sequence',
    serialNo: 'Serial Number',
    settle: 'Settle',
    settleAmount: 'Settle Amount',
    settleAllPending: 'Settle All Pending',
    settleGameMatch: 'Settle Game Match',
    settleLastDayGameMatch: 'Settle Last Day Game Match',
    settlement: 'Settlement',
    settleTime: 'Settle Time',
    settleView: 'View',
    settlePay: 'Pay',
    settleEdit: 'Edit',
    shareRatio: 'Share Ratio',
    show: 'Show',
    showAll: 'Show All',
    sid: 'SID',
    site: 'Site',
    siteCode: 'Site Code',
    siteList: 'Site List',
    siteName: 'Site Name',
    siteType: 'Site Type',
    sn: 'S/N',
    smsPhone: 'Phone',
    smsContent: 'Content',
    smsStatus: 'Status',
    smsSendTime: 'Send Time',
    smsType: 'Type',
    sorting: 'Sorting',
    sortType: 'Sort Type',
    sourceType: 'Source Type',
    sportBetResult: 'Sport Bet Result',
    sportMatchRecord: 'Sport Match Record',
    sportMatchSetting: 'Sport Match Setting',
    stage: 'Stage',
    state: 'State',
    startCopy: 'Start Copy',
    startMatch: 'Start Match',
    startUpdate: 'Start Update',
    startDate: 'Start Date',
    startTime: 'Start Time',
    status: 'Status',
    steps: 'Steps',
    stepBonus: 'Step Bonus',
    stopAfterFailure: 'Stop After Failure',
    subject: 'Subject',
    subSiteCount: 'Sub Site Count',
    subtotal: 'Subtotal',
    success: 'Success',
    successRate: 'Transaction success rate',
    successAmounntRate: 'Amount success rate',
    successfullyExport: 'Successfully Export',
    summaryAdjustment: 'Adjustment',
    summaryBonus: 'Bonus',
    summaryCompanyWinLoss: 'Company Win/Loss',
    summaryProfit: 'Profit',
    summaryReport: 'Summary Report',
    summaryTable: 'Summary Table',
    summaryTotalBet: 'Total Bet Member Count',
    superiorAffiliateInfo: 'Superior Affiliate Info',
    superiorAffiliateName: 'Superior Affiliate Name',
    superiorAffiliateCode: 'Superior Affiliate Code',
    superiorAffiliateLevel: 'Superior Affiliate Level',
    superiorCategory: 'Superior Category',
    superiorAffiliatePaymentChannel: 'Superior Affiliate Deposit Channel',
    supplement: 'Supplement',
    supplementDeposit: 'Supplement Deposit',
    supportType: 'Support Type',
    sureWin: 'Sure Win',
    sureWinBonus: 'Sure Win Bonus',
    sureWinMinSpin: 'Sure Win Min Spin',
    suspend: 'Suspend',
    switch: 'Switch',
    sync: 'Sync',
    szCnyControl: 'SZCny Withdrawal Control',
    switchPayment: 'Switch Payment',
    systemPayment: 'System Payment',
    targetType: 'Target Type',
    targetId: 'Target ID',
    targetName: 'Target Name',
    teamA: 'Team A',
    teamB: 'Team B',
    teamC: 'Team C',
    teamD: 'Team D',
    teamE: 'Team E',
    teamF: 'Team F',
    team16: 'Top 16',
    team8: 'Top 8',
    team4: 'Top 4',
    team2: 'Championship',
    teamGroup: 'Team Group',
    teamName: 'Team Name',
    teamNameEn: 'Team Name English',
    teamNameLocal: 'Team Name Local',
    teamOne: 'Team One',
    teamOneIcon: 'Team One Icon',
    teamOneResult: 'Team One Result',
    teamTwo: 'Team Two',
    teamTwoIcon: 'Team Two Icon',
    teamTwoResult: 'Team Two Result',
    teamBackgroundImage: 'Team Background Image',
    teamBackgroundImageDark: 'Team Background Image Dark',
    telephone: 'Telephone',
    thirtyDaysdw: 'Thirty Days deposit - withdraw',
    thirdParty: 'Third Party',
    thirdPartyName: 'Third Party Name',
    thirdPartyTotalBet: 'Third Party Total Bet',
    thirdPartyTotalPayout: 'Third Party Total Payout',
    thirdPartyTotalWin: 'Third Party Total Win',
    thirdSerialNo: 'Third Serial Number',
    thirdTicket: 'Third Ticket (Bet/Deposit)',
    thisMonth: 'This Month',
    thisWeek: 'This Week',
    ticketSetting: 'Ticket Setting',
    tips: 'Tips',
    title: 'Title',
    toApplying: 'To Applying',
    toBeforePaid: 'To Be Paid',
    toBePaid: 'To Be Paid',
    toChecking: 'To Checking',
    today: 'Today',
    toPay: 'To Pay',
    toPaymentOnGoing: 'To Payment On Going',
    topic: 'Topic',
    toreview: 'To Review',
    toSuspend: 'To Suspend',
    total: 'Total',
    totalActiveMembers: 'Total Active Member Count',
    totalActualRate: 'Total Interest Amount',
    totalAdjustment: 'Total Adjustment',
    totalBalance: 'Total Balance',
    totalBet: 'Total Bet',
    totalBetAmount: 'Total Bet Amount',
    totalBetCount: 'Total Bet Count',
    totalBetMemberCount: 'Total Member Bet Count',
    totalBetRecords: 'Total Bet Records',
    totalBonus: 'Total Bonus',
    totalCommission: 'Total Commission',
    totalCommissionProfit: 'Total Commission Profit',
    totalCompanyProfit: 'Total Member Profit',
    totalDeductAmount: 'Total Deduct Amount',
    totalDeposit: 'Total Deposit',
    totalDepositAmount: 'Total Deposit Amount',
    totalDepositCount: 'Total Deposit Count',
    totalDepositMemberCount: 'Total Member Deposit Count',
    totalWithdrawMemberCount: 'Total Member Withdraw Count',
    totalDepositedAmount: 'Total deposited amount',
    totalDepositNumber: 'Total Deposit Member',
    totalDownlineAffiliates: 'Total Downline Affiliates',
    totalDownlineMembers: 'Total Downline Members',
    totalFailDeposit: 'Total Fail Deposit',
    totalFailDepositAmount: 'Total Fail Deposit Amount',
    totalFailWithdraw: 'Total Fail Withdraw',
    totalFailWithdrawAmount: 'Total Fail Withdraw Amount',
    totalFirstDeposit: 'Total First Deposit',
    totalMemberDepositAmount: 'Total Deposit Amount',
    totalMemberDepositCount: 'Total Deposit Count',
    totalNoOfDeposits: 'Total number of deposits',
    totalNoOfWithdrawals: 'Total number of withdrawals',
    totalPayout: 'Total Payout',
    totalPlaceAmount: 'Total Place Amount',
    totalPlaceMemberCount: 'Total Member Placed',
    totalPrivilegeAmount: 'Total Privilege Amount',
    totalPrivilegeClaimAmount: 'Total Privilege Claim Amount',
    totalPromo: 'Total Promotion',
    totalProfit: 'Total Profit',
    totalRebateAmount: 'Total Rebate Amount',
    totalRedeem: 'Total Redeem',
    totalRegister: 'Total Register',
    totalRegisterCount: 'Total Register Count',
    totalReimburseAmount: 'Total Reimburse Amount',
    totalSuccessDeposit: 'Total Success Deposit',
    totalSuccessDepositAmount: 'Total Success Deposit Amount',
    totalSuccessDepositPercentage: 'Total Success Deposit Percentage',
    totalSuccessWithdraw: 'Total Success Withdraw',
    totalSuccessWithdrawAmount: 'Total Success Withdraw Amount',
    totalTime: 'Total Time',
    totalTransferIn: 'Total Transfer In',
    totalTransferOut: 'Total Transfer Out',
    toUnderReview: 'To Under Review',
    totalValidBet: 'Total Valid Bet',
    totalWithdraw: 'Total Withdraw',
    totalWithdrawAmount: 'Total Withdraw Amount',
    totalWithdrawCount: 'Total Withdraw Count',
    totalWithdrawnAmount: 'Total withdrawn amount',
    transaction: 'Transaction',
    transactionStatus: 'Transaction Status',
    transactionTime: 'Transaction Time',
    transactionId: 'Transaction ID',
    transferDate: 'Transfer Date',
    transferId: 'Transfer ID',
    transferIn: 'Transfer In',
    transferOut: 'Transfer Out',
    transferTime: 'Transfer Time',
    transferType: 'Transfer Type',
    triggerType: 'Trigger Type',
    turnoverMultiple: 'Turnover Multiple',
    txnId: 'Txn ID',
    txnTime: 'Txn Time',
    type: 'Type',
    uefaMatchRecord: 'UEFA Match Record',
    uefaMatchSetting: 'UEFA Match Setting',
    uefaSettlement: 'UEFA Settlement',
    uefaTeamRecord: 'UEFA Team Record',
    uefaTeamSetting: 'UEFA Team Setting',
    undefined: 'Undefined',
    underMaintenance: 'Under Maintenance',
    underReview: 'Under review',
    unlockMember: 'Unlock Member',
    unlockSetting: 'Unlock Setting',
    unlockUser: 'Unlock User',
    url: 'Access URL',
    unsettleCommission: 'Unsettled Commission',
    update: 'Update',
    updateAlias: 'Update Alias',
    updateAwards: 'Update Awards',
    updateBy: 'Updated By',
    updateCommissionModel: 'Update Commission Model',
    updateCommissionRate: 'Update Commission Rate',
    updateFinancialLevel: 'Update Financial Level',
    updateMaintenanceTime: 'Update Maintenance Time',
    updatePassword: 'Update Password',
    updatePlatformFee: 'Update Platform Fee',
    updatePaymentFee: 'Update Payment Fee',
    updateRiskLevel: 'Update Risk Level',
    updateStatus: 'Update Status',
    updateTime: 'Updated Time',
    updateVIPLevel: 'Update VIP Level',
    upgradeToCheck: 'UPGRADE_TO_UNDER_REVIEW',
    upgradeToPaymentOnGoing: 'UPGRADE_TO_PAYMENT_ON_GOING',
    upLineLoginName: 'Upline Login Name',
    upload: 'Upload',
    upperName: 'Upper Name',
    usdtControl: 'USDT Withdrawal Control',
    usdtDepositAmount: 'USDT Deposit Amount',
    usdtDepositCount: 'USDT Deposit Count',
    username: 'Username',
    userType: 'User Type',
    validate: 'Validate',
    validity: 'Validity',
    validBet: 'Valid Bet',
    validBetPerSpin: 'Valid Bet Per Spin',
    value: 'Value',
    vcallName: 'Landline phone number',
    view: 'View',
    viewChoice: 'View Questionnaire Choices',
    viewDetails: 'View Details',
    viewLog: 'View Log',
    viewLoginName: 'View Login Name',
    vipLevel: 'VIP Level',
    vipRebateDetails: 'VIP Rebate Record Details',
    vipRebateRecord: 'VIP Rebate Record',
    vipRules: 'VIP Rules',
    vipUpgradeList: 'VIP Upgrade List',
    vipWheelSettings: 'VIP Wheel Settings',
    vipWheelRecords: 'VIP Wheel Records',
    virtualVotes: 'Virtual Votes',
    visitNumber: 'Visit Number',
    votes: 'Votes',
    voteTime: 'Vote Time',
    walletType: 'Wallet Type',
    way: 'Way',
    web: 'Web',
    webMessage: 'Web Message',
    win: 'Win',
    winRate: 'Win Rate',
    winnerTeam: 'Winner',
    withdraw: 'Withdraw',
    withdrawableBalance: 'Withdrawable Balance',
    withdrawArea: 'Withdraw Area',
    withdrawChannel: 'Withdraw Channel',
    withdrawExchangeRate: 'Withdraw Exchange Rate',
    withdrawalFailureCause: 'Withdrawal Failure Cause',
    withdrawalFailureType: 'Withdrawal Failure Type',
    withdrawAmount: 'Withdraw Amount',
    withdrawCard: 'Withdraw Card',
    withdrawCode: 'Withdraw Code',
    withdrawCount: 'Withdraw Count',
    withdrawDate: 'Withdraw Date',
    withdrawMemberCount: 'Withdraw Member Count',
    withdrawName: 'Withdraw Name',
    withdrawPayoutBonus: 'Withdrawal/Payout/Bonus/Adjust',
    withdrawPlatform: 'Withdraw Platform',
    withdrawPlatformList: 'Withdraw Platform List',
    withdrawPlatformName: 'Withdraw Platform Name',
    withdrawReviewType: 'Withdraw Review Type',
    withdrawStatus: 'Withdraw Status',
    withdrawType: 'Withdraw Type',
    yes: 'Yes',
    yesterday: 'Yesterday',
    tigerCardType: 'Card Type',
    tigerCardOpeType: 'Operate Type',
    tigerCardSource: 'Source/Target',
    tigerCardBeforeCount: 'Before Count',
    tigerCardRealCount: 'Gold Tiger Card Count',
    tigerCardVirtualCount: 'Virtual Card Count',
    tigerCardPeriod: 'Period',
    tigerCardTime: 'Lottery Time',
    tigerCardBonusTime: 'Receive Time',
    timeType: 'Time Type',
    count: 'Count',
    host: 'host',
    port: 'port',
    user: 'user',
    from: 'from',
    addEmailSetting: 'Add Email Setting',
    editEmailSetting: 'Edit Email Setting',
    signName: 'Sign Name',
    secretId: 'Secret ID',
    secretKey: 'Secret Key',
    setUndefined: 'Set Undefined',
    appId: 'Application ID',
    templateId: 'Template ID',
    addSmsSetting: 'Add SMS Setting',
    editSmsSetting: 'Edit SMS Setting',
    requestExportToExcel: 'Request Export to Excel',
    downloadManagerRecord: 'Download Manager Record',
    downloadExportedExcel: 'Download Exported Excel',
    redPacketAmountAfterReachingLimit: 'Red Packet Amount After Reaching Limit',
    requestBy: 'Request By',
    requestTime: 'Request Time',
    progress: 'Progress',
    download: 'Download',
    updateProgress: 'Update Progress',
    add_new: 'New',
    date_added: 'Date Range added.',
    please_add_at_least_one_date_time: 'Please add at least 1 date and 1 time.',
    multiple_rain_added: 'Multiple Daily Rain durations added.',
    date_already_exists: 'Date already exists.',
    time_already_exists: 'Time range already exists.',
    add_single_daterange: 'Add Single DateRange',
    adde_multiple_daterange: 'Add Multiple DateRange',
    add_single_date_steps:
      'Step: Select Date & Time range, and then click `Add`.',
    add_multiple_date_steps:
      'Steps: Select all combination(s) of date & time range, click "Add Date" & "Add\n' +
      '          Time", and then click "Add" to add.',
    pick_date: 'Pick a Date',
    pick_start_date: 'Pick Start Date/Time',
    pick_end_date: 'Pick End Date/Time',
    please_select_datetime: 'Please select Date From & Date To',
    add_date: 'Add Date',
    add_time: 'Add Time',
    dates: 'Date(s):',
    timeranges: 'Time Range(s):',
    timeZone: 'Time Zone',
    image: 'Image',
    imageName: 'Image Name',
    addImage: 'Add Image',
    editImage: 'Edit Image',
    eta: 'ETA',
    browse: 'Browse',
    imageSite: 'Image Site',
    imageRemark: 'Image Remark',
    selectedImage: 'Selected Image',
    poster: 'Poster',
    posterType: 'Poster Type',
    createConfig: 'Create Config',
    defaultConfigHint: 'Default',
    defaultConfigSearchBarHint: 'Search by ',
    config: 'Config',
    configCode: 'Config Code',
    configValue: 'Config Value',
    configDescribes: 'Config Describes',
    s3Url: 'S3 URL',
    allSites: 'All Sites',
    allSource: 'All Source Type',
    rank: 'Rank',
    processResult: 'Process Result',
    winner: 'Winner',
    teamIcon: 'Team Icon',
    other: 'Other',
    dailyDetail: 'Daily Detail',
    dailySummary: 'Daily Summary',
    officialWebData: 'Official Web Data',
    packageWebData: 'Package Web Data',
    payoutAmount: 'Payout Amount',
    indBet: 'Bet Amount',
    indProfit: 'Profit',
    memberDepositCount: 'Deposit Count',
    indBonusAmount: 'Bonus Amount',
    indRebateAmount: 'Rebate Amount',
    indAdjustAmount: 'Adjust Amount',
    grossProfit: 'Gross Profit',
    football: 'Football',
    basketball: 'Basketball',
    esport: 'E-Sport',
    competitionType: 'Competition Type',
    competitionName: 'Competition Name',
    competitionTime: 'Competition Time',
    addCompetition: 'Add Competition',
    editCompetition: 'Edit Competition',
    addCompetitionCode: 'Add Competition Code',
    editCompetitionCode: 'Edit Competition Code',
    addMemberTreasureKey: 'Add Member Treasure Key',
    deductMemberTreasureKey: 'Deduct Member Treasure Key',
    keyQuantity: 'Key Quantity',
    keyQuantityBefore: 'Key Quantity Before',
    keyQuantityAfter: 'Key Quantity After',
    checkInDate: 'Check In Date',
    consecutiveDay: 'Consecutive Day Count',
    summoner: 'Summoner',
    memberSummon: 'Member Summoned',
    summonBonus: 'Summon Bonus',
    depositBonus: 'Deposit Bonus',
    depositDayCount: 'Day Count',
    redirectUrlApp: 'Redirect URL APP',
    redirectUrlWeb: 'Redirect URL Web',
    redirectUrlH5: 'Redirect URL H5',
    popUpDurationSeconds: 'Pop Up Duration (Seconds)',
    popUpTime: 'Pop Up Time',
    notificationType: 'Notification Type',
    notificationContent: 'Notification Content',
    unbind: 'unbind',
    winloss: 'winloss',
    profit2: 'Dep-With',
    affiliateBetRebateRecord: 'Affiliate Bet Rebate Record',
    affiliateBetRebateDetails: 'Affiliate Bet Rebate Details',
    adjustRebateAmount: 'Adjust Rebate Amount',
    prizeLevel: 'Prize Level',
    addLotteryRecord: 'Add Lottery Record',
    finalAmount: 'Final Amount',
    rewardType: 'Reward Type',
    refereeEligibility: 'Referee Eligibility',
    oneTimeBonusSetting: 'One Time Bonus Setting',
    depositRebateSetting: 'Deposit Rebate Setting',
    betRebateSetting: 'Bet Rebate Setting',
    referrerEligibility: 'Referrer Eligibility',
    affiliateLoginName: 'Affiliate Login Names (Separate by comma)',
    needPhoneVerified: 'Need Phone Verified',
    needIpUniqueInMonth: 'Need IP Unique In A Month',
    refereeMinTotalDeposit: 'Referee Min Total Deposit',
    refereeMinTotalBet: 'Referee Min Total Bet',
    minReferCount: 'Min Refer Count',
    maxReferCount: 'Max Refer Count',
    bonusAmountRules: 'Bonus Amount Rules',
    referDistance: 'Refer Distance',
    depositRebateRate: 'Deposit Rebate Rate',
    maxTotalBonusAmount: 'Max Total Bonus Amount',
    maxDailyBonusAmount: 'Max Daily Bonus Amount',
    rebateRateSlot: 'Rebate Rate Slot',
    rebateRateFish: 'Rebate Rate Fish',
    rebateRateLive: 'Rebate Rate Live',
    rebateRateSport: 'Rebate Rate Sport',
    rebateRatePoker: 'Rebate Rate Poker',
    allMembers: 'All Members',
    selectedVipLevels: 'Selected VIP Levels',
    selectedAffiliates: 'Selected Affiliates',
    virtualAmount: 'Virtual Amount',
    virtualMultiplier: 'Virtual Multiplier',
    batchDistributeRebate: 'Batch Distribute Rebate',
    distribute: 'Distribute',
    totalAmountDistribute: 'Total Amount Distribute',
    noOfBonusRecord: 'Number of Bonus Record',
    whitelist: 'Whitelist',
    blacklist: 'Blacklist',
    accessType: 'Access Type',
    domainType: 'Domain Type',
    valueType: 'Value Type',
    ipList: 'IP List',
    ipRange: 'IP Range',
    countryCode: 'Country Code',
    lower: 'Lower',
    upper: 'Upper',
    addMemberLotteryRecord: 'Add Member Lottery Record',
    addFakeLotteryRecord: 'Add Fake Lottery Record',
    approveSummonRecord: 'Approve Summon Record',
    updateAffiliateLevel: 'Update Affiliate Level',
    memberMoney: 'Member Money',
    memberPoint: 'Member Point',
    eggs: 'Eggs',
    memberInquiry: 'Member Inquiry',
    depositApplication: 'Deposit Application Member Count',
    withdrawApplication: 'Withdraw Application Member Count',
    affiliateApplication: 'Affiliate Application Count',
    memberApplication: 'Member Application Count',
    memberInbox: 'Member Inbox Count',
    totalMembers: 'Total Members',
    currentMembers: 'Current Members',
    todayDepositAmount: 'Today Deposit Amount',
    todayWithdrawAmount: 'Today Withdraw Amount',
    todayWithdrawDepositProfit: 'Today Withdraw Deposit Profit',
    manualMoney: 'Manual Money',
    manualPoint: 'Manual Point',
    totalGrossProfit: 'Total Gross Profit',
    casinoBetAmount: 'Casino Bet Amount',
    casinoProfit: 'Casino Profit',
    casinoRollingAmount: 'Casino Rolling Amount',
    casinoGrossProfit: 'Casino Gross Profit',
    slotBetAmount: 'Slot Bet Amount',
    slotProfit: 'Slot Profit',
    slotRollingAmount: 'Slot Rolling Amount',
    slotGrossProfit: 'Slot Gross Profit',
    sportBetAmount: 'Sport Bet Amount',
    sportProfit: 'Sport Profit',
    sportRollingAmount: 'Sport Rolling Amount',
    sportGrossProfit: 'Sport Gross Profit',
    miniGameBetAmount: 'Mini Game Bet Amount',
    miniGameProfit: 'Mini Game Profit',
    miniGameRollingAmount: 'Mini Game Rolling Amount',
    miniGameGrossProfit: 'Mini Game Gross Profit',
    unsuccessFrom30min: 'Unsuccessful from 30 Minutes Ago',
    unsuccessIn10time: 'Channels with 10 Unsuccessful',
    platformAccountName: 'Platform Account Name',
    subRegCount: 'Sub Register Count',
    subDepositCount: 'Sub Deposit Count',
    ftdDownlineAmount: 'FTD Downline Amount',
    changeReferrer: 'Change Referrer',
    memberShareRatio: 'Member Share Ratio',
    updateShareRatio: 'Update Share Ratio',
    downlineMembers: 'Downline Members',
    todayRegCount: 'Today Register Count',
    yesterdayRegCount: 'Yesterday Register Count',
    displayStartTime: 'Display Start Time',
    displayEndTime: 'Display End Time',
    redirectType: 'Redirect Type',
    redirectUrl: 'Redirect URL',
    redirectButton: 'Redirect Button',
    sendFrom: 'Sent From',
    expiryTime: 'Expiry Time',
    noExpiryTime: 'No Expiry Time',
    hasPopUp: 'Has PopUp',
    noPopUp: 'No PopUp',
    selectedVIP: 'selected VIP',
    affiliateBalance: 'Affiliate Balance',
    memberBalance: 'Member Balance',
    affiliatePoint: 'Affiliate Point',
    memberCount: 'Member Count',
    affiliateWithdrawAmount: 'Affiliate Withdraw Amount',
    withdrawTransferDiff: 'Withdraw Transfer Difference',
    liveRolling: 'Live Rolling Amount',
    slotRolling: 'Slot Rolling Amount',
    sportRolling: 'Sport Rolling Amount',
    recycleMoney: 'Recycle Money',
    createMemberPointRecord: 'Create Member Point Record',
    claimed: 'Claimed',
    notClaimed: 'Pending',
    displayAmount: 'Display Amount',
    activate: 'Activate',
    withdrawPassword: 'Withdraw Password',
    updateWithdrawPassword: 'Update Withdraw Password',
    self: '(SELF)',
    levelUpBet: 'Level Up Bet',
    retainLevelBet: 'Retain Level Bet',
    retainLevelDays: 'Retain Level Days',
    editSystemValueRules: 'Edit System Value Rule',
    addSystemValueRules: 'Add System Value Rule',
    walletInfo: 'Wallet Info',
    fiat: 'Fiat',
    usdt: 'USDT',
    fiatBalance: 'Fiat Balance',
    usdtBalance: 'USDT Balance',
    toggleWallet: 'Toggle Wallet Type',
    historyRecord: 'History Record',
    nonHistoryRecord: 'Non-History Record',
    productId: 'Product ID',
    dayBetRebatePercentage: 'Day Bet Rebate Percentage',
    needUniqueDevice: 'Need Unique Device',
    uniqueIpCount: 'Unique Ip Count',
    uniqueDeviceCount: 'Unique Device Count',
    referFriendAnalysis: 'Refer Friend Analysis',
    referRelationQuery: 'Refer Relation Query',
    referCount: 'Refer Count',
    referBonus: 'Refer Bonus',
    successCount: 'Success Count',
    betBonus: 'Bet Bonus',
    batchFrozenMember: 'Batch Frozen Member',
    threePointShots: 'Entire Match 10 Three Points Shots In',
    shotPoints: 'Any Player Scored 30 Points',
    scoringShots: 'Any Players Scored With 13 Shots',
    foulOut: 'Any Player Getting Foul Out',
    gender: 'Gender',
    Male: 'Male',
    Female: 'Female',
  },
  message: {
    startenddatemore3months: 'Start and End date cannot more than 3 months',
    addRemarkSuccess: 'Add Remark Success',
    addSuccess: 'Add Success',
    adjustSuccess: 'Adjust Amount Success',
    affiliateApproved: 'Affiliate Approved',
    affiliateDisabled: 'Affiliate Disabled',
    autopaySuccess: 'Autopay Success',
    cancelBbDachaSuccess: 'Cancel BB Dacha Success',
    cancelSuccess: 'Cancel Success',
    cancelDepositSuccess: 'Cancel Deposit Success',
    cancelQuizSuccess: 'Cancel Quiz Success',
    cancelRolloverRecordSuccess: 'Cancel Rollover Record Success',
    changeAffiliateSuccess: 'Change Affiliate Success',
    confirmUnbindAffiliateAccesss: 'Confirm Unbind Superior Agent',
    changeSuccess: 'Change Success',
    commissionPaySuccess: 'Commission Pay Success',
    confirmToAdjust:
      'Are you sure to make adjustment? Unable to make other adjustment after confirmation.',
    confirmCancel:
      'Confirm that you want to cancel this record, the operation cannot be undone',
    confirmCancelBbDacha:
      'Confirm that you want to cancel this BB Dacha, the operation cannot be undone',
    confirmCancelMatch:
      'Confirm that you want to cancel this match, the operation cannot be undone',
    confirmCancelQuiz:
      'Confirm that you want to cancel this quiz, the operation cannot be undone',
    confirmCancelRebate:
      'Confirm that you want to cancel the rebate records, the operation cannot be undone',
    confirmCopy: 'Do you want to copy from ',
    confirmCopyTo: ' to ',
    confirmDelete:
      'Confirm that you want to delete these data, the operation cannot be undone',
    confirmRunAtOnce:
      "Confirm that you want to execute this execute job now (Even if it's closed)",
    confirmDelivered:
      'Confirm that this gift is delivered, the operation cannot be undone',
    confirmEndMatch:
      'Confirm that this match is ended, the operation cannot be undone',
    confirmRebate:
      'Confirm that you want to distribute all rebate, the operation cannot be undone',
    confirmBatchRebate:
      'Confirm that you want to distribute these chosen rebates, the operation cannot be undone',
    confirmSingleRebate:
      'Confirm that you want to distribute this rebate, the operation cannot be undone',
    confirmRedeem:
      'Confirm that you want to redeem this gift as cash, the operation cannot be undone',
    confirmRefer:
      'Confirm that you want to distribute all refer friend promo, the operation cannot be undone',
    confirmReset:
      'Confirm that you want to reset these data, the operation cannot be undone',
    confirmSettlement: 'Confirm that you want to proceed with the settlement?',
    confirmStartMatch: 'Confirm that you want to proceed to begin the match?',
    confirmToChecking:
      'Confirm that you want revert the settlement to checking?',
    confirmToPay: 'Confirm that you want proceed to payment?',
    confirmToCheck: 'Do you want to update？',
    confirmUpdate: 'Do you want to update?',
    confirmUpdatePayment: 'Do you want to update payment?',
    confirmUnbind: 'Confirm that you want unbind bank card?',
    copyToClipboard: ' has been copied to clipboard.',
    copySuccess: 'Copy Success',
    deleteSuccess: 'Delete Success',
    runAtOnceSuccess: 'Run job Success',
    dailyMinRegLimitCannotExceedMax:
      'Daily Minimum Register Limit Cannot Exceed Max Register Limit',
    dailyMinFirstDepositCannotExceedMax:
      '일일 최소 입금액은 최대 입금액을 초과할 수 없습니다',
    depositSuccess: 'Deposit Success',
    editAmountSuccess: 'Edit Amount Success',
    editRemarkSuccess: 'Edit Remark Success',
    editSuccess: 'Edit Success',
    editMemberDetail: 'Edit Member Detail',
    endMustAfterStart: 'End time must be later than start time',
    enterMemberDetails: 'Enter Member Details',
    failedToUploadImage: 'Failed to upload image',
    failedToUploadApp: 'Failed to upload app',
    freezeMemberSuccess: 'Freeze Member Success',
    unfreezeMemberSuccess: 'Unfreeze Member Success',
    found: 'Found ',
    gameMatchEnded: 'Game Match Ended',
    giftDelivered: 'Gift Delivered',
    giftNoCashRedeemAmount:
      'Gift does not have cash redeem amount, please set the cash redeem amount of the gift first',
    giftOutForDelivery: 'Gift is out for delivery',
    importSuccess: 'Import Success',
    inputDigits: 'Please input digits',
    inputPassword: 'Please input the password',
    inputPasswordAgain: 'Please input the password again',
    invalidFileType: 'Invalid file type ',
    length6To12: 'Length should be 6 to 12',
    levelUpCreditMoreThan: 'Level up credit must be more than ',
    logoutPlayerSuccess: 'Logout Player Success',
    maxGreaterThanMin: 'Maximum must be greater than minimum',
    memberNotInSite: 'Member could not be found in this site',
    affiliateNotInSite: 'Member could not be found in this site',
    memberFreeGameLogin:
      'Login name, separate with commas if there is more than one name (maximum 200 names)',
    memberFreeGameRound: 'Free game round',
    memberFreeGameIds:
      'Game IDs can be used, separated by commas if there is more than one; the maximum length (including commas) is 200',
    multipleQuerySeparatedBySpace:
      'Multiple query conditions are separated by spaces',
    multiwheelprizeform: 'Insert 0 as Grand Prize',
    noAvailablePrivilege: 'Currently there are no available privilege',
    passwordLength:
      'The password cannot be less than 6 digits or more than 12 digits',
    paymentUpdate: 'Copy to other way or level? if not just press update',
    paySuccess: 'Pay Success',
    pleaseEnterAffiliateNameOrAffiliateCode:
      'Please enter affiliate name or affiliate code',
    promoDistributionSuccess: 'Promo Distribution Success',
    redeemCashSuccess: 'Redeem Cash Success',
    reenterPassword: 'Please re-enter the password',
    rebateSuccess:
      'VIP Rebate distribution in process, please refresh and check on the records later.',
    registerSuccess: 'Register Success',
    registerSuccessInd:
      'Register Success. Please set deposit display setting for new afiliate.',
    referSuccess: 'Distribute Refer Friend Promo Success',
    replySuccess: 'Reply Success',
    removePreviousLevel: 'Please remove previous level',
    removeRemarkSuccess: 'Remove Remark Success',
    replyFeedback: 'Reply Feedback',
    resetSuccess: 'Reset Success',
    resendSuccess: 'Resend Success',
    selectDateNotMoreThan3Month:
      'The date range cannot exceed 3 months. Please choose again',
    selectPreviousLevel: 'Please select previous level',
    selectSiteFirst: 'Please select a site first',
    selectSiteAndGameTypeFirst: 'Please select a site and a game type first',
    selectPaymentType: 'Please input payment type',
    selectMobilePayment: 'Please select mobile payment',
    selectWebPayment: 'Please select web payment',
    settlementToChecking: 'Updated to checking',
    settlementToPay: 'Updated to payment',
    settled: 'Successfully settled',
    startMustBeforeEnd: 'Start time must be ealier than end time',
    success: 'Success',
    summarySuccess:
      'Summary for the input period will be generated, please kindly wait',
    transferSuccess: 'Transfer Success',
    twoPasswordNotMatch: 'Two password does not match',
    unbindSuccess: 'Unbind member bank card success',
    unlockMemberSuccess: 'Unlock Member Success',
    unlockUserSuccess: 'Unlock User Success',
    updateBelongTypeSuccess: 'Update Web Belong Type Success',
    updateCommissionModelSuccess: 'Update Commission Model Success',
    updateCommissionRateSuccess: 'Update Commission Rate Success',
    updateFinancialLevelSuccess: 'Update Financial Level Success',
    updatePasswordSuccess: 'Update Password Success',
    updatePayment: 'Update Payment',
    updateProceed: 'Update nodes to proceed to payment edit',
    updateRiskLevelSuccess: 'Update Risk Level Success',
    updateSuccess: 'Update Success',
    updateWithdraw: 'Update Withdraw',
    updateTimeTypeSuccess: 'Update Time Type Success',
    updateToApplySuccess: 'Update to Apply Success',
    updateToBeforePaidSuccess: 'Update to Before Paid Success',
    updateToFailSuccess: 'Update to Fail Success',
    updateToPaySuccess: 'Update to Payment On Going Success',
    updateToSuspendSuccess: 'Update to Suspend Success',
    updateToUnderReviewSuccess: 'Update to Under Review Success',
    updateUserTypeSuccess: 'Update UserType Success',
    updateVIPLevelSuccess: 'Update VIP Level Success',
    updateWithdrawTypeSuccess: 'Update Withdraw Type Success',
    updateShareRatioSuccess: 'Update Share Ratio Success',
    useCommaToSeparateDomain:
      'Please use "," to separate domain name if insert multiple domain',
    validateAdsTypeRequired: 'Ads Type is required',
    validateActionRequired: 'Action is required',
    validateActivePlayerRequired: 'Active Player is required',
    validateAdjustAmountRequired: 'Adjust Amount is required',
    validateAdjustReasonRequired: 'Adjust Reason is required',
    validateAdjustTypeRequired: 'Adjust Type is required',
    validateAdjustmentRequired: 'Adjustment is required',
    validateAffiliateFbIdRequired: 'Facebook pixel ID is required',
    validateAffiliateFbTokenRequired: 'Facebook pixel token is required',
    validateAffiliateFbUrlRequired: 'Facebook pixel url is required',
    validateAffiliateCodeRequired: 'Affiliate Code is required',
    validateAffiliateLevelRequired: 'Affiliate Level is required',
    validateAffiliateNameRequired: 'Affiliate Name is required',
    validateAliasRequired: 'Alias is required',
    validateAllBonusRequired: 'All Bonus is required',
    validateAlphaNumericOnly: 'Please enter string and number only',
    validateAmountRequired: 'Amount is required',
    validateAnnouncementTypeRequired: 'Announcement Type is required',
    validateAnswerOneRequired: 'Question One Answer is required',
    validateAnswerOneAtLeastTwoChoices:
      'Question One Answer need to have at least to choices',
    validateAnswerTwoRequired: 'Question Two Answer is required',
    validateAnswerTwoAtLeastTwoChoices:
      'Question Two Answer need to have at least to choices',
    validateAnswerThreeRequired: 'Question Three Answer is required',
    validateAnswerThreeAtLeastTwoChoices:
      'Question Three Answer need to have at least to choices',
    validateAppTypeRequired: 'App type is required',
    validateAtLeastSixChar: 'Please enter at least 6 characters',
    validateAwardsRequired: 'Awards is required',
    validateAwayTeamRequired: 'Away Team is required',
    validateAwayTeamIconRequired: 'Away Team Icon is required',
    validateAwayTeamResultRequired: 'Away Team Result is required',
    validateBankRequired: 'Bank is required',
    validateBankCardNumberRequired: 'Bank Card Number is required',
    validateBankCodeRequired: 'Bank Code is required',
    validateBankNameRequired: 'Bank Name is required',
    validateBankTypeRequired: 'Bank Type is required',
    validateBeanNameRequired: 'Bean Name is required',
    validateBetAmountRequired: 'Bet Amount is required',
    validateBonusRequired: 'Bonus is required',
    validateBonusAmountRequired: 'Bonus Amount is required',
    validateBonusAmountRatioRequired: 'Bonus Amount Ratio is required',
    validateBonusTypeRequired: 'Bonus Type is required',
    validateCardAccountRequired: 'Card Account is required',
    validateCardAddressRequired: 'Card Address is required',
    validateCardNumberRequired: 'Card Number is required',
    validateCategoryRequired: 'Category is required',
    validateCauseRequired: 'Cause is required',
    validateChoiceOneRequired: 'Please input home team and away team',
    validateChoiceTwoRequired: 'Please select game type',
    validateChoiceThreeRequired: 'Please select game type',
    validateChoiceRequired: 'Choice is required',
    validateClassNameRequired: 'Class Name is required',
    validateCodeRequired: 'Code is required',
    validateCodeExist:
      'Code exists, please input a different code. - Existing Code : ',
    validateColorRequired: 'Color is required',
    validateCommissionRequired: 'Rate is required',
    validateCommissionFormat: 'Rate must be between 0 to 1',
    validateCommissionModelRequired: 'Commission Model is required',
    validateConfigGroupRequired: 'Config Group is required',
    validateConfirmPasswordRequired: 'Confirm Password is required',
    validateConfirmPasswordSize: 'Confirm Password has to be 6-12 characters',
    validateContentRequired: 'Content is required',
    validateCopyFinancialLevel: 'Please select at least 1 level to copy',
    validateCountryImageRequired: 'Country Image is required',
    validateCountryRequired: 'Country is required',
    validateCronExpressionFormat: 'Cron Expression invalid format',
    validateCronExpressionRequired: 'Cron Expression is required',
    validateCurrencyRequired: 'Currency is required',
    validateCurrencyCodeRequired: 'Currency Code is required',
    validateCurrencyNameRequired: 'Currency Name is required',
    validateCustomerSupportAddressFormat:
      'Customer Support Address JSON String is invalid',
    validateDailyMinRequired: 'Daily Min Spin is required',
    validateDailyMaxRequired: 'Daily Max Spin is required',
    validateDateRequired: 'Date is required',
    validateDayWithdrawCountRequired: 'Daily Withdraw Count is required',
    validateDefaultBonusRequired: 'Default Bonus is required',
    validateDepositAmountRequired: 'Deposit Amount is required',
    validateDepositExchangeRateRequired: 'Deposit Exchange Rate is required',
    validateDepositPerSpinRequired: 'Deposit Per Spin is required',
    validateDesktopImageRequired: 'Desktop Image is required',
    validateDesktopBannerRequired: 'Desktop Banner is required',
    validateDeviceRequired: 'Device is required',
    validateDomainRequired: 'Domain is required',
    validateEmailRequired: 'Email is required',
    validateEmailFormat: 'Please input the correct email address format',
    validateEndDateRequired: 'End Date is required',
    validateEndTimeRequired: 'End Time is required',
    validateExchangeRateRequired: 'Exchange Rate is required',
    validateExpressCompanyRequired: 'Express Company is required',
    validateFailReasonRequired: 'Fail Reason is required',
    validateFeeRateRequired: 'Fee Rate is required',
    validateFreezeTypeRequired: 'Freeze type is required',
    validateFinancialLevelRequired: 'Financial level is required',
    validateFileRequired: 'App file is required',
    validateFrequencyRequired: 'Frequency is required',
    validateGameCodeRequired: 'Game Code is required',
    validateGameNameRequired: 'Game Name is required',
    validateGameTypeRequired: 'Game Type is required',
    validateHomeTeamRequired: 'Home Team is required',
    validateHomeTeamIconRequired: 'Home Team Icon is required',
    validateHomeTeamResultRequired: 'Home Team Result is required',
    validateIconRequired: 'Icon is required',
    validateIdentifyCodeRequired: 'Identify Code is required',
    validateIpRequired: 'IP is required',
    validateJobNameRequired: 'Job Name is required',
    validateLabelRequired: 'Label is required',
    validateLevelRequired: 'Level is required',
    validateLevelNameRequired: 'Level Name is required',
    validateLevelUpCreditRequired: 'Level Up Credit is required',
    validateLimitNumberRequired: 'Limit Number is required',
    validateLoginNameRequired: 'Login name is required',
    validateLoginNameSize: 'Login name has to be 6-12 characters',
    validateMaintenanceTimeRequired: 'Maintenance Time is required',
    validateMallCodeRequired: 'Mall Code is required',
    validateMallKeyRequired: 'Mall Key is required',
    validateMallNameRequired: 'Mall Name is required',
    validateMatchTitleRequired: 'Match Title is required',
    validateMatchTimeRequired: 'Match Time is required',
    validateMaxDailyWithdrawRequired: 'Maximum Daily Withdraw is required',
    validateMaxDailyWithdrawNumber:
      'Maximum Daily Withdraw must not be lesser than or equal to 0',
    validateMaxDailyWithdrawGreater:
      'Max Daily Withdraw must be greater than max withdraw amount',
    validateMaxDailyWithdrawTimesRequired:
      'Maximum Daily Withdraw Time is required',
    validateMaxDailyWithdrawTimesNumber:
      'Maximum Daily Withdraw Time must not be lesser than or equal to 0',
    validateMaxDepositRequired: 'Maximum Deposit is required',
    validateMaxDepositGreater: 'Max Deposit must be greater than min deposit',
    validateMaxWithdrawAmountRequired: 'Maximum Withdraw Amount is required',
    validateMaxWithdrawAmountNumber:
      'Maximum Withdraw Amount must not be lesser than or equal to 0',
    validateMaxWithdrawAmountGreater:
      'Max Withdraw Amount must be greater than min withdraw amount',
    validateMemberTypeRequired: 'Member type is required',
    validateMemberIdRequired: 'Member ID is required',
    validateMenuTitleRequired: 'Menu Title is required',
    validateMinBalanceRequired: 'Minimum balance is required',
    validateMinDepositRequired: 'Minimum deposit is required',
    validateMinDepositLesser:
      'Min Deposit Amount must be lesser than max deposit amount',
    validateMinWithdrawRequired: 'Minimum withdraw is required',
    validateMinWithdrawNumber:
      'Minimum withdraw must not be lesser than or equal to 0',
    validateMinWithdrawLesser:
      'Min Withdraw Amount must be lesser than max withdraw amount',
    validateMobileImageRequired: 'Mobile Image is required',
    validateMobileBannerRequired: 'Mobile Banner is required',
    validateMobileMessageRequired: 'Mobile Message is required',
    validateNameRequired: 'Name is required',
    validateNeedRegisterRequired: 'Need Register is required',
    validateNetProfitRequired: 'Net Profit is required',
    validateNextActivationTimeRequired: 'Next Activation Time is required',
    validateNextGetBetEndTime: 'Next Get Bet End Time is required',
    validateNextGetBetPage: 'Next Get Bet Page is required',
    validateNextGetBetStartTime: 'Next Get Bet Start Time is required',
    validateNoNullValue: 'No null value can exist',
    validateNoRepeatAmount: 'No same amount can exist',
    validateNumberOnly: 'Please enter number only',
    validateNumberFourDecimalOnly:
      'Please enter number greater than 0 and only 4 decimal places',
    validateOneTimeSpinRequired: 'One Time Spin is required',
    validateOnlyThreeChar: 'Please enter 3 characters',
    validateOrderTrackingNoRequired: 'Order Tracking No is required',
    validateOsRequired: 'Please select at least one Operating System',
    validateParamRequired: 'Parameter is required',
    validatePasswordRequired: 'Password is required',
    validatePasswordSize: 'Password has to be 6-12 characters',
    validatePaymentFeeFormat: 'Payment Fee must be between 0 to 1',
    validatePaymentNameRequired: 'Payment Name is required',
    validatePaymentTypeRequired: 'Payment Type is required',
    validatePayResultTypeRequired: 'Pay Result Type is required',
    validatePayTypeRequired: 'Pay Type is required',
    validatePayTypeCodeRequired: 'Pay Type Code is required',
    validatePayTypeNameRequired: 'Pay Type Name is required',
    validatePayTypeNameCodeDifferent:
      'Pay Type Name and Pay Type Code can not be same value',
    validatePayoutRequired: 'Payout is required',
    validatePlatformRequired: 'Platform is required',
    validatePlatformAccountRequired: 'Platform Account is required',
    validatePlatformCodeRequired: 'Platform Code is required',
    validatePlatformDetailsRequired: 'Amounts for selected platform must be filled',
    validatePlatformFeeFormat: 'Platform Fee must be between 0 to 1',
    validatePlatformNameRequired: 'Platform Name is required',
    validatePlatformSelected: 'Selected platforms are duplicated',
    validatePointsRequired: 'Points is required',
    validatePriorityRequired: 'Priority is required',
    validatePrivilegeRequired: 'Privilege Name is required',
    validatePromoCodeRequired: 'Promo Code is required',
    validatePromoTypeRequired: 'Promo Type is required',
    validateQueryNumberRequired: 'Query Number is required',
    validateQueryRestrictionRequired: 'Query Restriction is required',
    validateQuestionOneRequired: 'Question One is required',
    validateQuestionRequired: 'Question is required',
    validateQuizTimeRequired: 'Quiz Time is required',
    validateQuizTitleRequired: 'Quiz Title is required',
    validateRateRequired: 'Rate is required',
    validateRealNameRequired: 'Real Name is required',
    validateReasonRequired: 'Reason is required',
    validateReasonTypeRequired: 'Reason Type is required',
    validateRebateAmountRequired: 'Rebate Amount is required',
    validateRebatePercentageRequired: 'Rebate Percentage is required',
    validateMaxRebateRequired: 'Max rebate is required',
    validateRecipientRequired: 'Recipient is required',
    validateRecordTimeRequired: 'Record Time is required',
    validateRedeemPointsRequired: 'Redeem Points is required',
    validateRedirectRequired: 'Redirect is required',
    validateReenterPasswordRequired: 'Please re-enter the password',
    validateRemarkRequired: 'Remark is required',
    validateRequestUrlRequired: 'Request URL is required',
    validateResultDateRequired: 'Result Date is required',
    validateResultNumber: '3 digits number is required',
    validateRebateFormat: 'Rebate must be between 0 to 1',
    validateRevenueShareFormat: 'Revenue Share must be between 0 to 1',
    validateRewardGroupRequired: 'Reward Group is required',
    validateRewardGroupNameRequired: 'Reward Group Name is required',
    validateRewardGroupDownlineQuotaRequired: 'Downline Quota is required',
    validateRewardGroupDownlineQuotaFormat:
      'Downline Quota must be greater than 0',
    validateRiskLevelRequired: 'Risk level is required',
    validateRoleRequired: 'Role is required',
    validateRoleNameRequired: 'Role Name is required',
    validateRolloverRequired: 'Rollover is required',
    validateGameRolloverRequired: "Game Rollover is required",
    validateGameRolloverSelectRequired: "Please select Game Rollover type",
    validateRoundRequired: 'Round is required',
    validateRulesRequired: 'Rules is required',
    validateSchemaNameRequired: 'Schema Name is required',
    validateSequenceRequired: 'Sequence is required',
    validateSiteRequired: 'Site is required',
    validateSiteCodeRequired: 'Site Code is required',
    validateStageRequired: 'Stage is required',
    validateStartTimeRequired: 'Start Time is required',
    validateStateRequired: 'State is required',
    validateStatusRequired: 'Status is required',
    validateStepsRequired: 'Steps settings is required',
    validateStepBonusRequired: 'Bonus settings is required',
    validateStopAfterFailureRequired: 'Stop After Failure is required',
    validateSubjectRequired: 'Subject is required',
    validateSuccess: 'Validate Success',
    validateSupportTypeRequired: 'Support Type is required',
    validateSureWinBonusRequired: 'Sure Win Bonus is required',
    validateSureWinMinSpinRequired: 'Sure Win Min Spin is required',
    validateTeamGroupRequired: 'Team Group is required',
    validateTeamIconRequired: 'Team Icon is required',
    validateTeamNameRequired: 'Team Name is required',
    validateTeamNameEnRequired: 'Team Name English is required',
    validateTeamNameLocalRequired: 'Team Name Local is required',
    validateTeamOneRequired: 'Team One is required',
    validateTeamOneIconRequired: 'Team One Icon is required',
    validateTeamTwoRequired: 'Team Two is required',
    validateTeamTwoIconRequired: 'Team Two Icon is required',
    validateTelephoneRequired: 'Telephone is required',
    validateThirdSerialNumberRequired: 'Third Serial Number is required',
    validateThreeDigitsNumbeRequired: '3 digits number is required',
    validateTitleRequired: 'Title is required',
    validateTimeTypeRequired: 'Time Type is required',
    validateTransactionIdRequired: 'Transaction ID is required',
    validateTransactionStatusRequired: 'Transaction Status is required',
    validateTriggerTypeRequired: 'Trigger Type is required',
    validateTypeRequired: 'Type is required',
    validateUrlFormat: 'Please enter proper url',
    validateUsernameRequired: 'Username is required',
    validateUsernameSize: 'Username has to be 6-12 characters',
    validateUserTypeRequired: 'User Type is required',
    validateValidBetRequired: 'Valid Bet is required',
    validateValidBetPerSpinRequired: 'Valid Bet Per Spin is required',
    validateValueRequired: 'Value is required',
    validateVIPRequired: 'VIP is required',
    validateWalletTypeRequired: 'Wallet Type is required',
    validateWayRequired: 'Way is required',
    validateWebMessageRequired: 'Web Message is required',
    validateWholeNumberOnly: 'Please enter whole number only',
    validateWinnerRequired: 'Winner is required',
    validateWithdrawAmountRequired: 'Withdraw Amount is required',
    validateWithdrawChannel: 'Sequence cannot be repeated',
    validateWithdrawExchangeRateRequired: 'Withdraw Exchange Rate is required',
    validateWithdrawPlatformNameRequired: 'Withdraw Platform Name is required',
    validateWithdrawTypeRequired: 'Withdraw Type is required',
    validateTigerCardPeriodRequired: 'Period required',
    validateTigerCardTimeRequired: 'Lottery Time required',
    validateTigerCardBonusTimeRequired: 'Receive Time required',
    validateTigerSumAwardRequired: 'Total bonus required',
    validateVirtualCountRequired: 'Count is required',
    validateHostRequired: 'Host is required',
    validatePortRequired: 'Port is required',
    validateSignNameRequired: 'Sign Name is required',
    validateSearchCondition: 'Enter at least one query condition',
    validateSecretIdRequired: 'Secret ID is required',
    validateSecretKeyRequired: 'Secret Key is required',
    validateAppIdRequired: 'Application ID is required',
    validateTemplateIdRequired: 'Template ID is required',
    requestExportToExcelDone1: 'Request was sent out successful, please click ',
    requestExportToExcelDone2: ' to check progress and download.',
    validateImageRequired: 'Image is required',
    validateImageNameRequired: 'Image Name is required',
    validatePosterTypeRequired: 'Poster Type is required',
    validateConfigCodeRequired: 'Config Code is required',
    validateConfigValueRequired: 'Config Value is required',
    validateConfigTypeRequired: 'Config Type is required',
    resultCalculateSuccess: 'Result is calculated successfully',
    buildIsInQueue: 'Build is in queue',
    roleMustOnlyOne: 'Please select a role that requires authorization',
    validateCompetitionTypeRequired: 'Competition Type is required',
    validateCompetitionNameRequired: 'Competition Name is required',
    validateCompetitionTimeRequired: 'Competition Time is required',
    validateKeyQuantityRequired: 'Key quantity is required',
    validateMonthRequired: 'Year/Month is required',
    validateTimeRequired: 'Time is required',
    validateShareRatioFormat: 'Share Ratio must be between 0 to 1',
    syncMemberDetailSuccess: 'Sync Member Detail Success',
    timeExist: 'Time already exists',
    wrongPopupTime: 'Selected popup time is invalid',
    selectPopupTime: 'Please select Popup Time.',
    summonSuccess: 'Summon Success',
    updateAffiliateLevelSuccess: 'Update Affiliate Level Success',
    validateMinDayRegLimitRequired: 'Daily Minimum Register Limit is required',
    validateMaxDayRegLimitRequired: 'Daily Maximum Register Limit is required',
    validateMinDayDepositAmountRequired:
      'Daily Minimum Deposit Amount is required',
    validateMaxDayDepositAmountRequired:
      'Daily Maximum Deposit Amount is required',
    validateDayRegProbabilityRequired: 'Daily Register Probability is required',
    validateDayDepositAmountProbabilityRequired:
      'Daily First Deposit Probability is required',
    validateMediaDescRequired: 'Media Description Required',
    validateMediaUrlRequired: 'Video link required',
    validateDisplayUrlRequired: 'Video display link required',
    validateModeRequired: 'Mode required',
    validateAutoplayRequired: 'Autoplay required',
    validatePlanOddsRequired: 'Investment rate required',
    validatePlanDaysRequired: 'Investment period required',
    validatePlanLimitMinRequired: 'Minimum investment limit required',
    validatePlanLimitMaxRequired: 'Maximum investment limit required',
    affiliateReactivated: 'Affiliate Reactivated',
    required_6_digits_code: 'Must be composed of 6 digits code.',
    required_only_digits_and_alphabet:
      'Must be composed of digits and alphabet',
    validateProductIdRequired: 'Product ID is required',
    validateBetRebatePercentageRequired: 'Bet Rebate Percentage is required',
  },
  menu: {
    undefined: '',
    Dashboard: 'Dashboard',
    Member: 'Member',
    'Member List': 'Member List',
    'Member List ID': 'Member List',
    'Member Details': 'Member Details',
    'Member Edit Log': 'Member Edit Log',
    'Member Feedback': 'Member Feedback',
    'Member Refer': 'Member Refer',
    'Member Refer Summary': 'Member Refer Summary',
    'Member Freeze Record': 'Member Freeze Record',
    'Member Amount Adjust': 'Member Amount Adjust',
    'Member Privilege Blacklist': 'Member Privilege Blacklist',
    'Member Bank Card History': 'Member Bank Card History',
    'Member Sms': 'Member Sms History',
    'Import Bet Records': 'Import Bet Records',
    'Member Transfer Record': 'Member Transfer Record',
    'Member VIP': 'Member VIP',
    'Member Bet Record': 'Member Bet Record',
    'PG Pending': 'PG Pending Records',
    'Member Privilege': 'Member Privilege',
    'Member Rollover Record': 'Member Rollover Record',
    'Member Distribute Privilege': 'Member Distribute Privilege',
    'Member Profit Ranking': 'Profit Ranking',
    'Member Affiliate Change Log': 'Member Affiliate Change Log',
    'Member Group Send SMS': 'Member Group Send SMS',
    'Member Vip Upgrade': 'Member VIP Upgrade',
    Affiliate: 'Affiliate',
    'Affiliate Amount Adjust': 'Affiliate Amount Adjust',
    'Affiliate Summary': 'Affiliate Summary',
    'Affiliate Monthly Settlement': 'Affiliate Monthly Settlement',
    'Affiliate Monthly Settlement Detail':
      'Affiliate Monthly Settlement Detail',
    'Affiliate Settlement Checking': 'Affiliate Settlement',
    'Affiliate Settlement Payment': 'Affiliate Settlement Payment',
    'Affiliate Domain Management': 'Affiliate Domain Management',
    'Affiliate List': 'Affiliate List',
    'Affiliate Details': 'Affiliate Details',
    'Affiliate Financial Management': 'Affiliate Financial Management',
    'Affiliate Facebook Pixel Management':
      'Affiliate Facebook Pixel Management',
    'Affiliate Deposit Display': 'Affiliate Deposit Display',
    'Site Affiliate Commission': 'Site Affiliate Commission',
    'Aff Announcement Management': 'Affiliate Announcement Management',
    'Affiliate Announcement': 'Affiliate Announcement',
    'Affiliate Announcement Type': 'Affiliate Announcement Type',
    'Affiliate Member Dummy Record': 'Affiliate Member Dummy Record',
    'App Version': 'App Version Control',
    'Auto Withdraw Setting': 'Auto Withdraw Setting',
    'Deposit Management': 'Deposit Management',
    'Online Deposit': 'Online Deposit',
    'Deposit Record': 'Deposit Record',
    'Withdrawal Auto Process': 'Withdrawal Auto Process',
    'Withdrawal Management': 'Withdrawal Management',
    'Withdrawal Process': 'Withdrawal Process',
    'Withdrawal Affiliate Process': 'Withdrawal Affiliate Process',
    'Withdrawal Record': 'Withdrawal Record',
    'Withdrawal Affiliate Record': 'Withdrawal Affiliate Record',
    'Withdrawal Platform Balance': 'Withdrawal Platform Balance',
    'Withdraw Review': 'Withdrawal Review',
    'Withdrawal Bank Blacklist': 'Withdrawal Bank Blacklist',
    'Withdraw Tips Setting': 'Withdraw Tips Setting',
    'Reward Group': 'Reward Group',
    Applying: 'Applying',
    'Affiliate Applying': 'AffiliateApplying',
    'Affiliate Under review': 'Affiliate Under review',
    BW1: 'BW1',
    BW2: 'BW2',
    BW3: 'BW3',
    BW4: 'BW4',
    BW5: 'BW5',
    'To be affiliate paid': 'To be affiliate paid',
    'Affiliate Payment on going': 'Affiliate Payment on going',
    'Crypto Affiliate Payment on going': 'Crypto Affiliate Payment on going',
    'Ewallt Affiliate Payment on going': 'Ewallet Affiliate Payment on going',
    'Alipay Affiliate Payment on going': 'Alipay Affiliate Payment on going',
    'Affiliate Automatic Payment': 'Affiliate Automatic Payment',
    'Affiliate Suspend': 'Affiliate Suspend',
    'Under review': 'Under review',
    'To be paid': 'To be paid',
    'Payment on going': 'Normal Payment',
    'Crypto Payment on going': 'Crypto Paying',
    'Ewallt Payment on going': 'Ewallet Paying',
    'Alipay Payment on going': 'Alipay Paying',
    'Automatic Payment': 'Automatic Paying',
    'Waiting Auto Pay': 'Waiting Auto Pay',
    'AutoWithdraw Under review': 'Under review',
    'AutoWithdraw Withdrawal Record': 'Withdrawal Record',
    'Fail Review': 'Fail Review',
    Suspend: 'Suspend',
    Promotion: 'Promotion',
    'Privilege Info': 'Privilege Info',
    'Home Banner': 'Home Banner',
    'Promotion pages': 'Promotion pages',
    'Edit Promo': 'Edit Promo',
    'Multi Wheel': 'Multi Wheel',
    'Multi Wheel List': 'Multi Wheel Member List',
    'Multi Wheel Setting': 'Multi Wheel Setting',
    'Multi Wheel Additional List': 'Multi Wheel Additional List',
    'Team Votes': 'Team Votes',
    'Team Votes Records': 'Team Votes Records',
    'Team Votes Settings': 'Team Votes Settings',
    'Tiger Card': 'Tiger Card',
    'Telephone Numbers': 'Telephone Numbers',
    'Ads Popout': 'Ads Popout Page',
    'Add Ads Popout': 'Add Ads Popout',
    'Edit Ads Popout': 'Edit Ads Popout',
    'Red Packet Rain': 'Red Packet Rain',
    'Red Packet Rain List': 'Red Packet Rain List',
    'Member Free Game': 'Member Free Game',
    Lottery: 'Lottery',
    'Lottery Record List': 'Lottery Record List',
    'Lottery Result List': 'Lottery Result List',
    'Vietnam Lottery Record List': 'Vietnam Lottery Record List',
    'Vietnam Lottery Result List': 'Vietnam Lottery Result List',
    'VIP Rebate Record': 'VIP Rebate Record',
    'Bet Rebate Record': 'Bet Rebate Record',
    'Refer Friend': 'Refer Friend Record',
    'Refer Friend Rebate Record': 'Refer Friend Rebate Record',
    Gift: 'Gift Management',
    'Gift Info': 'Gift Info',
    'Gift Record': 'Gift Records',
    'Promo Application': 'Promo Application',
    'Game Steps': 'Game Steps',
    'BB Dacha': 'BB Dacha',
    'VIP Wheel': 'VIP Wheel',
    'Bet Wheel': 'Bet Wheel',
    'Piggy Bank': 'Piggy Bank',
    'Loss Rebate Record': 'Loss Rebate Record',
    'Fish Red Packet': 'Fish Red Packet Config',
    'Bet Game Type Rebate Record': 'Bet Game Type Rebate Record',
    'Site Interest Plan': 'Site Interest Plan',
    'Interest Plan Order Record': 'Interest Plan Order Record',
    'Promo Type': 'Promo Type',
    'Site Management': 'Site Management',
    Message: 'Message',
    Announcement: 'Announcement',
    'Announcement Type': 'Announcement Type',
    'VIP Rebate': 'VIP Rebate',
    VIP: 'VIP',
    Game: 'Game',
    'Game Tag': 'Game Tag',
    'Financial Level': 'Financial Level',
    'Risk Level': 'Risk Level',
    'Ip Label': 'IP Label',
    'Site Platform Management': 'Site Platform Management',
    'Game Match': 'Game Match',
    'Game Quiz': 'Game Quiz',
    'Member Rebate': 'Member Rebate',
    Questionnaire: 'Questionnaire',
    UEFA: 'UEFA',
    'UEFA Team': 'UEFA Team',
    'UEFA Match': 'UEFA Match',
    'UEFA Winner': 'UEFA Winner',
    'Refer Friend Manager': 'Refer Friend Manager',
    'Sport Match': 'Sport Match',
    'Nba Match': 'NBA Playoffs',
    'Nba Match Preseason': 'NBA Preseason',
    Redirect: 'Redirect Management',
    Permission: 'Permission',
    Menu: 'Menu',
    Roles: 'Roles',
    User: 'User',
    Report: 'Report',
    'Privilege Record': 'Privilege Record',
    'Summary Report': 'Summary Report',
    'Privilege Report': 'Privilege Report',
    'Deposit Report': 'Deposit Report',
    'Withdraw Report': 'Withdraw Report',
    'Finance Report': 'Finance Report',
    'Affiliate Report': 'Affiliate Report',
    'Affiliate Deposit Members': 'Affiliate Deposit Members',
    'Platform Game Report': 'Platform Game Report',
    'Site Member Report': 'Member Report',
    'Deposit Member Detail': 'Deposit Member Detail',
    System: 'System',
    'Operator Log': 'Operator Log',
    'Member Platform': 'Member Platform',
    Site: 'Site',
    Config: 'Config',
    Currency: 'Currency',
    Platform: 'Platform',
    'Site Platform': 'Site Platform',
    Schedule: 'Schedule',
    'Platform Account': 'Platform Account',
    'Data Dictionary': 'Data Dictionary',
    'Manual Summary': 'Manual Summary',
    'Affiliate Manual Summary': 'Affiliate Manual Summary',
    'Payment Management': 'Payment Management',
    'Payment Display List': 'Payment Display List',
    'Bank Info': 'Bank Info',
    'Bank Card': 'Bank Card',
    'Payment Type': 'Payment Type',
    Payment: 'Payment',
    'Add Payment': 'Add Payment',
    'Edit Payment': 'Edit Payment',
    CurrencyRate: 'Currency Rate',
    'Payment Display': 'Payment Display',
    'Withdraw Platform': 'Withdraw Platform',
    'Withdraw Setting': 'Withdraw Setting',
    'Site Withdraw Platform': 'Site Withdraw Platform',
    'Deposit Setting': 'Deposit Setting',
    'Withdraw Display': 'Withdraw Display',
    'Payment Bank Management': 'Payment Bank Management',
    'Withdraw Platform Bank Management': 'Withdraw Platform Bank Management',
    'Summary Register Report': 'Summary Register Report',
    'Summary Fdp Report': 'Summary Fdp Report',
    'Summary Deposit Report': 'Summary Deposit Report',
    'Summary Withdraw Report': 'Summary Withdraw Report',
    'Summary Active Report': 'Summary Active Report',
    'Summary Member Report': 'Summary Member Report',
    'Finance Feedback': 'Finance Feedback',
    'Email Setting': 'Email Setting',
    'SMS Setting': 'SMS Setting',
    DownloadManager: 'Download Manager',
    'Error Queue': 'Error Message Queue',
    GoogleAuth: 'Google Auth',
    'Image Setting': 'Image Setting',
    'Privi Christmas': 'Merry Christmas',
    'Affiliate APK Build': 'Affiliate APK Build',
    'Channel Summary': 'Channel Summary',
    'Adjustment Reason': 'Adjustment Reason',
    'User Login Log': 'User Login Log',
    'Online Stats': 'Online Stats',
    'Promotion Activity': 'Promotion Activity',
    'Platform Competition': 'Platform Competition',
    'Platform Competition Code': 'Platform Competition Code',
    'Event Treasure Key': 'Event Treasure Key Record',
    'Event Check In Record': 'Event Check In Record',
    'User Action Log': 'User Action Log',
    'Competition Management': 'Competition Management',
    'Competition List': 'Competition List',
    'Competition Code': 'Competition Code',
    'Affiliate Recalculate': 'Affiliate Recalculate',
    Utils: 'Utils',
    'Ip Location': 'Ip Location',
    'Member Summon Reward Record': 'Member Summon Reward Record',
    'Redirect Notification': 'Redirect Notification',
    'Member Summon Event': 'Member Summon Event',
    'Member Summon List': 'Member Summon List',
    'Affiliate Bet Rebate Record': 'Affiliate Bet Rebate Record',
    'UEFA Lottery': 'UEFA Lottery',
    'UEFA Lottery Record': 'UEFA Lottery Record',
    'UEFA Lottery Result': 'UEFA Lottery Result',
    'Member Refer Event': 'Member Refer Event',
    'Member Refer Record': 'Member Refer Record',
    'Member Refer Reward List': 'Member Refer Reward List',
    'Refer Rebate': 'Refer Rebate',
    'Domain Access': 'Domain Access',
    'KRW Stats Report': 'KRW Statistics Report',
    'Member Refer Pak': 'Member Refer Pak',
    'Member Refer Summary Pak': 'Member Refer Summary Pak',
    Pm: 'Private Message',
    'MQ Log': 'MQ Log',
    'System Config': 'System Config',
    'Promotion Coupon': 'Deposit Coupon',
    'Member Invite Limit': 'Member Invite Limit',
    'Member Point Record': 'Member Point Record',
    'Affiliate Change Log': 'Affiliate Parent Change Log',
    'Media Display Settings': 'Media Display Settings',
    'Vip Report': 'Vip Report',
    'System Value Rules': 'System Value Rules',
    'Sites Config': 'Site Config',
    'Mooncake Fest Spin Record': 'Mooncake Fest Spin Record 2024',
    'Platform WC': 'Platform WC',
    'Site Platform Report': 'Site Platform Report',
    'Refer Friend Eligibility': 'Refer Friend Eligibility',
    'Member Refer Analysis': 'Member Refer Analysis',
  },
  google: {
    google_auth_menu: 'Google Authenticator',
    download_install: 'Download App',
    add_key: 'Add Security Key',
    bind_verify: 'Bind Google Auth',
    popup_title: 'Google Authenticator',
    popup_msg:
      "A Google Authenticator is a dynamic password tool that automatically refreshes every 30 seconds. When performing sensitive actions such as transferring funds, making withdrawals, or adjusting security settings in the proxy's backend, you need to input this 6-digit identity verification code for authentication.",
    popup_hint:
      'The identity authenticator must be used in conjunction with the proxy account.',
    i_know: 'I Know',
    download_way: 'Way to Download：',
    download_through_way: '1.Via Download Link：',
    ios_download: 'iOS Download',
    android_download: 'Android Download',
    ios_scan_download: 'Scan IOS Download',
    android_scan_download: 'Scan Android Download',
    if_cannot_download_you_can:
      '2.If you cannot download it, you can search for it on the Apple App Store."',
    or_search_in_google: '", or search on the Android App Store.',
    download_and_install: '" to download & install。',
    next_step: 'Next',
    keyin_pass: 'Key In Password & Next',
    keyin_your_password: 'Enter Login Password',
    tips_installed:
      'Quick tip: Once it\'s downloaded and installed, simply click "Next" to proceed.',
    qr_code: 'QR Code',
    secret_key: 'Secret Key',
    add_step: 'Steps to add',
    add_step_desc:
      'Open Google Authenticator, click on the bottom right corner\'s "+" sign, select "Manually enter the key," and enter any account to bind it with the above-mentioned secret key.  (Scanning the QR code can automatically add it.)',
    check_example: 'View Example',
    back: 'Back',
    small_tips: 'Quick tip:',
    small_tips_info_1:
      '1. If you lose your phone or uninstall the authentication app, the key can help you recover the authenticator. Please keep it safe.',
    small_tips_info_2:
      '2.For the security of your account, please do not mark the proxy account and proxy backend address during the binding process.',
    keyin_6_digit_google: 'Please key in Google 6 digits Auth Code.',
    auth_code: 'Google Authenticator',
    bind_now: 'Bind Now',
    please_enter_password: 'Please enter your password',
    added_success: 'Google Authenticator code added successfully',
    if_not_google_auth_then:
      "If you haven't bound Google Authenticator, there's no need to fill it out.",
    google_auth_code: 'Enter 6 digits Google Authenticator Code',
    register_count: 'Today Register Count:',
    register_limit: 'Today Register Limit:',
  },
  posterType: {
    overall: 'Overall Referral',
    app: 'APP Referral',
    sponsor: 'Sponsor Referral',
    gift: 'Gift Referral',
    competition: 'Competition Referral',
    event: 'Event Referral',
    crypto: 'Crypto Referral',
    affiliate: 'Affiliate Benefit',
  },
  siteAppVersion: {
    version: 'Version',
    os: 'Operating System',
    appType: 'App Type',
    apkType: 'App Secret Key',
    fileUploadedSuccessfully: 'File Upload Successfully',
    IOS: 'IOS',
    ANDROID: 'Android',
    ALL_SITE: 'All Sites',
    SPORT: 'Sport App',
    ESPORT: 'Esport App',
    NORMAL: 'Normal',
    NEW_KEY: 'Antivirus',
    appUpload: 'App Upload',
    editAppUpload: 'Edit App Upload',
  },
  affiliateApk: {
    currentVersion: 'Current Version',
    buildStatus: 'Build Status',
    latestVersion: 'Latest Version',
    buildApk: 'Build APK',
    buildAllApk: 'Build All APK',
    editParam: 'Edit Param',
    status: {
      IN_QUEUE: 'IN QUEUE',
      IN_PROGRESS: 'IN PROGRESS',
      SUCCESS: 'SUCCESS',
    },
  },
  priviEvent: {
    status: 'Record Status',
    cancel: 'Cancelled',
    active: 'Active',
    settled: 'Settled',
  },
  realtimeStatistics: {
    APP: 'APP',
    H5: 'H5',
    Mobile: 'Mobile',
    PC: 'PC',
    APPLY_WITHDRAW: 'Apply Withdraw',
  },
  loginDevice: {
    WEB: 'WEB',
    H5: 'H5',
    ANDROID: 'ANDROID',
    IOS: 'IOS',
  },
  result: {
    win: 'WIN',
    draw: 'DRAW',
    loss: 'LOSS',
  },
  freeze: {
    NORMAL: 'Normal',
    TEMPORARY: 'Temporary',
    PERMANENT: 'Permanent',
    UNFREEZE: 'Unfreeze',
  },
  reviewStatus: {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
  },
  week: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: 'Bet Ratio-FISH',
    AGENT_SHARE_BET_RATIO_SLOT: 'Bet Ratio-SLOT',
    AGENT_SHARE_BET_RATIO_ESPORT: 'Bet Ratio-ESPORT',
    AGENT_SHARE_BET_RATIO_LIVE: 'Bet Ratio-LIVE',
    AGENT_SHARE_BET_RATIO_SPORT: 'Bet Ratio-SPORT',
    AGENT_SHARE_BET_RATIO_CASUAL: 'Bet Ratio-CASUAL',
    AGENT_SHARE_BET_RATIO_POKER: 'Bet Ratio-POKER',
    AGENT_SHARE_PROFIT_RATIO_FISH: 'Profit Ratio-FISH',
    AGENT_SHARE_PROFIT_RATIO_SLOT: 'Profit Ratio-SLOT',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: 'Profit Ratio-ESPORT',
    AGENT_SHARE_PROFIT_RATIO_LIVE: 'Profit Ratio-LIVE',
    AGENT_SHARE_PROFIT_RATIO_SPORT: 'Profit Ratio-SPORT',
    AGENT_SHARE_PROFIT_RATIO_CASUAL: 'Profit Ratio-CASUAL',
    AGENT_SHARE_PROFIT_RATIO_POKER: 'Profit Ratio-POKER',
  },
  gameTypeRolloverSetting: {
    anyTypes: 'All Game Types',
    specifyTypes: 'Specify Game Types',
    excludeTypes: 'Exclude Game Types',
    specifyTypesAmount: 'Specify Game Types Amount',
    specifyGameType: 'Specific Game Types and Amount',
  },
  domainType: {
    LANDING: 'Landing',
    DOWNLOAD: 'Download',
    AFFILIATE: 'Affiliate',
    HOME: 'Home',
  },
  pmStatus: {
    UNSENT: 'Unsent',
    SENDING: 'Sending',
    SENT: 'Sent',
  },
  pmRedirectType: {
    NONE: 'No Redirect',
    INNER: 'Inner Site Redirect',
    OUTER: 'Outer Site Redirect',
  },
  vipCategory: {
    BRONZE: 'BRONZE',
    SILVER: 'SILVER',
    GOLD: 'GOLD',
    PLATINUM: 'PLATINUM',
    DIAMOND: 'DIAMOND',
    WANGZE: 'WANGZE',
  },
  error: {
    400: 'Bad Request',
    401: 'Unauthorized Request',
    403: 'Forbidden',
    404: 'Resource cannot be found',
    405: 'Method Not Allowed',
    500: 'System Error',
    501: 'Duplicate Request ID',
    502: 'Server Not Found',
    504: 'Too Often Request',
    601: 'Token verification error',
    602: 'Token has expired',
    603: 'Token is missed',
    604: 'Account already login',
    605: 'Account already logged out',
    606: 'The selected role is already assigned to the user, please unassign this role before trying again',
    607: 'Site cannot be empty when user class is not site admin',
    700: 'Old and new password cannot be the same',
    701: 'Parameter already exist',
    707: 'Insufficient balance',
    800: 'Captcha verification error',
    801: 'Captcha code has expired',
    900: 'Member details could not be found',
    901: 'Affiliate record could not be found',
    912: 'Login name could not be found',
    922: 'Your account haven link to vcall config',
    1000: 'Login failed, username or password is wrong',
    1001: 'Login failed, account is disabled',
    1005: 'Login failed, account has been locked, please try again tomorrow',
    1006: 'Login failed, account does not exists',
    1007: 'The withdrawal order has been submitted, please try again in 20 minutes',
    1008: 'You have already bound Google Authenticator, please enter the Google verification code.',
    1009: 'The Google verification code was entered incorrectly.',
    1100: 'Admin is restricted from logging in',
    1101: 'Login account does not belongs to this site',
    9000: 'Failed to create scheduled job',
    9001: 'Failed to update scheduled job',
    9002: 'Failed to delete scheduled job',
    10009: 'Enter at least one query condition',
    10010: 'System announcement type name already exist',
    10011: 'System announcement title already exist',
    10012: 'System announcement type does not exist',
    10013: 'Announcement type is being used by existing announcement. Please proceed to delete existing announcement(s)',
    10020: 'Banner title already exist',
    10021: 'Banner ID not found',
    10030: 'Promo title already exist',
    10031: 'Promo ID not found',
    10032: 'Invalid file type for image',
    10033: 'Image banner upload failed',
    10034: 'Image promo upload failed',
    1004: 'You have added Google Authenticator. Please key in your Google Auth Code.',
    10040: 'System risk level name already exist',
    10041: 'System risk level color already exist',
    10050: 'System ip label already exist',
    10051: 'System ip label does not exist',
    10110: 'Platform retrieve pending transaction error',
    10111: 'Platform update pending transaction error',
    11000: 'affiliate does not exist',
    11006: 'Site affiliate commission already exist',
    11007: 'Site affiliate commission does not exist',
    11008: 'Affiliate record does not exist',
    11009: 'Affiliate commission must be more than child affiliate commission: ',
    11010: 'Referral affiliate cannot have downline affiliate',
    11011: 'Superior affiliate share ratio not set',
    11012: 'Superior affiliate share ratio not set for game',
    11013: 'Affiliate share ratio cannot exceed superior affiliate share ratio',
    11014: 'Affiliate share ratio total for all downlines cannot exceed superior affiliate share ratio',
    11015: 'Affiliate share ratio not set',
    11016: 'Member share ratio cannot exceed affiliate share ratio',
    12000: 'System privilege info name already exist',
    12001: 'System privilege info code already exist',
    12002: 'Please insert privilege group',
    12010: 'Currency name already exist',
    12011: 'Currency rate already set for this site',
    12020: "Current record's status is not SENDING",
    12030: 'Site name already exist',
    12031: 'Site code already exist',
    12032: 'This Site is parent site',
    12033: 'Parent Site can not be changed',
    12034: 'Payment name already exist',
    12035: 'Payment code already exist',
    12036: 'System privilege info does not exists',
    12037: 'System payment id does not exist',
    12038: 'The amount is greater than the max bonus',
    12039: 'The member is already blacklisted to the privilege',
    12040: 'System payment name does not exists',
    12041: 'Mall Name already exist',
    12042: 'Mall Code already exist',
    12050: 'System bank does not exists',
    12051: 'System bank name already exist',
    12052: 'System bank code already exist',
    12053: 'System bank card identify code exists',
    12054: 'System bank card already exist',
    12055: 'Site image is being used',
    14000: 'The login name already exist',
    14001: 'The telephone already exist',
    14002: 'The email already exist',
    14003: 'Affiliate code does not exist',
    14004: 'Affiliate code not belong to this site',
    14005: 'Affiliate commission must be less than superior affiliate commission: ',
    14006: 'Affiliate income must be less than superior affiliate income',
    14007: 'Affiliate registration status is still pending',
    14008: 'Affiliate commission must be more than superior affiliate commission: ',
    14009: 'Affiliate income must be more than superior affiliate income',
    14011: 'Affiliate already exist',
    14012: 'Can only edit affiliate to lower level',
    14013: 'Affiliate has downline, cannot set to lower level',
    14100: 'The affiliate does not have this downline member',
    14101: 'This affiliate does not have permission to perform this action',
    14110: 'Platform and Site with same name already exist',
    14111: 'The status must be in CLOSE for one month before delete',
    14112: 'The status of this Site Platform in CLOSE is less than one month, please delete after one month',
    14120: 'Financial Level, Currency, Site, and device with same name already exist',
    14121: 'Minimum withdraw amount must not less than 0',
    14122: 'Maximum withdraw amount must not less than 0',
    14123: 'Maximum withdraw times must not less than 0',
    14124: 'Daily maximum withdraw amount must not less than 0',
    14125: 'Minimum withdraw amount must not be greater than maximum amount',
    14126: 'Maximum withdraw amount must not be lesser than minimum amount',
    14127: 'Daily maximum withdraw amount must not be lesser than maximum withdraw amount',
    14130: 'Platform Account does not exist',
    14131: 'Platform Account being used by other Site Platform',
    15000: 'VIP name already exist on the site',
    15001: 'VIP does not exist',
    15002: 'VIP is being used by existing VIP, it cannot be deleted',
    15003: 'VIP rebate rule exists for given VIP level and game type',
    15004: 'VIP rebate rule does not exist',
    15005: 'VIP rebate already distributed',
    15008: 'Member rebate rule exists for given login name and game type',
    15009: 'Member rebate rule does not exist',
    15010: 'Financial level name already exist on the site',
    15012: 'Financial level does not exist',
    15020: 'Deposit Record does not exist',
    15021: 'Financial Level, Pay Type, Site, and Way with same name already exist',
    15030: 'Unable to process the JSON data',
    15040: 'Withdraw Dsiplay name already exist',
    15041: 'Withdraw Dsiplay does not exist',
    15050: 'Bank already exists in given payment',
    15051: 'Code already exists in given payment',
    15060: 'Withdraw platform id does not exist',
    15061: 'Bank already exists in given withdraw platform',
    15062: 'Code already exists in given withdraw platform',
    15063: 'Withdraw record does not exist',
    15064: 'Withdraw platform does not exist',
    15065: 'Withdraw card does not exist',
    15066: 'Withdraw platform name already exist',
    16000: '3 digit number is required',
    16001: 'Number should be 3 digits',
    16002: 'Lottery result has been announced for the selected date',
    16010: 'English team name exists',
    16011: 'Team does not exist',
    16012: 'Award does not exist',
    17010: 'Vote record does not exist',
    17011: 'Vote record is already cancelled',
    18000: 'Domain already exists',
    18001: 'Affiliate domain does not exist',
    18002: 'Facebook Pixel does not exist',
    18003: 'This affiliate already set facebook pixel',
    19000: 'Bank card already blacklisted',
    19002: 'Red packet rain duration overlap',
    19003: 'Refresh duration overlap',
    19004: 'Last digit is duplicate',
    19101: 'Ads title already exist.',
    19102: 'Ads ID not found.',
    19103: 'Ads for the site already turn on, please turn off existing ads',
    20000: 'SMS Setting already exist on the site',
    20100: 'Email Setting already exist on the site',
    20201: 'Wrong type',
    20202: 'Please insert ‘正常’ / ‘冻结’ ',
    20203: 'Birthday format error (yyyy-MM-dd)',
    20204: 'Risk level not found, please insert correct risk level(eg：1)',
    20205: 'Financial level not found，please insert correct financial level (eg：1)',
    20206: 'Please insert ’测试‘ / ’普通‘ / ’三方‘ / ’代理‘',
    20400: 'Rollover record does not exists',
    21000: 'Game does not exists',
    21100: 'The ticket for this member is reach max ticket limit',
    21101: 'Multi wheel not found for this site',
    22001: 'Member VIP Level max bonus is $',
    21005: 'App exists with same site, OS, App Type & Apk Type',
    21006: 'File and os that choosing not the same',
    22003: 'Match already exists',
    30000: 'PG update transaction status failed',
    24003: 'Result is already processed',
    27000: 'Key quantity is not enough',
    31000: 'Some matches are not ended on the selected date, please wait until all matches are ended',
    31001: 'No matches on the selected date',
    45300: 'Member already has dummy record on the selected date',
    45301: 'Member dummy record on the selected date does not exists',
    45302: 'Member does not belong to any affiliate'
  },
}
