<template>
  <div
    class="sideWrap has-logo"
  >
    <SidebarLogo
      :collapse="isCollapse"
    />
    <SidebarSearchInput
      :isVisible="!isCollapse"
    />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :collapse="isCollapse"
        :unique-opened="false"
        :default-active="activeMenu"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        mode="vertical"
      >
        <SidebarItem
          :is-collapse="isCollapse"
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :has-new-user="hasNewUser"
        />
      </el-menu>
    </el-scrollbar>
    <audio ref="notificationAudioRef">
      <source src="@/assets/tones/chime.mp3" type="audio/mpeg">
    </audio>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import SidebarItem from './SidebarItem.vue'
import SidebarLogo from './SidebarLogo.vue'
import SidebarSearchInput from "./SidebarSearchInput.vue";
import variables from '@/styles/_variables.scss'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { getMemberWithdrawRecordApplySimple, getMemberWithdrawRecordApply, getMemberWithdrawRecordBeforePaid, getMemberWithdrawRecordPay } from '../../../api/member-withdraw-record'
import { getFinanceFeedbackCount } from '../../../api/finance-feedback'
import moment from 'moment'
import { hasPermission } from '../../../utils/util'
import { getNewRegisterMemberLists } from "../../../api/member";
import { isKorea } from "@/utils/site"

export default defineComponent({
  components: {
    SidebarSearchInput,
    SidebarItem,
    SidebarLogo
  },
  setup() {
    const isMounted = ref(false);
    const hasNewUser = ref(false);
    const notificationAudioRef = ref();
    const startDate = new Date();
    startDate.setDate(startDate.getDate());
    const defaultStartDate = convertStartDate(startDate);
    const defaultEndDate = convertEndDate(new Date());
    const store = useStore()
    const route = useRoute()
    const sidebar = computed(() => {
      return store.state.app.sidebar
    })
    const routes = computed(() => {
      return store.state.menu.routes
    })

    const activeMenu = computed(() => {
      const { meta, path } = route
      if (meta !== null || meta !== undefined) {
        if (meta.activeMenu) {
          return meta.activeMenu
        }
      }
      return path
    })

    const isCollapse = computed(() => {
      return !sidebar.value.opened
    })

    function convertStartDate(date) {
      return moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    function convertEndDate(date) {
      return moment(date).format('YYYY-MM-DD') + ' 23:59:59'
    }

    const checkQuery = () => {
      const query = {
        withdrawDate: [defaultStartDate, defaultEndDate]
      }
      query.withdrawDate = query.withdrawDate.join(',');
      query.memberType = "NORMAL,TEST,OUTSIDE,PROMO_TEST";
      if (!query.siteId) {
        query.siteId = store.state.user.siteId;
      } else {
        query.siteId = store.state.user.sites[0].id
      }
      return query;
    }

    const spliceSocket = (event) => {
      const socket = JSON.parse(JSON.stringify(useStore().state.socket.event));
      socket.forEach(e => {
        if (e.event === event) {
          useStore().state.socket.event.splice(useStore().state.socket.event.indexOf(e), 1);
        }
      });
    }

    const checkOutstandingWithdraw = async() => {
      const query = checkQuery();
      if (query.siteId !== null) {
        const { data: ret } = await getMemberWithdrawRecordApply(query);
        sessionStorage.setItem("WITHDRAW", ret.total);
      }
    };

    const checkOutstandingAutoWithdraw = async() => {
      const query = checkQuery();
      const { data: ret } = await getMemberWithdrawRecordApplySimple(query);
      sessionStorage.setItem("WITHDRAW", ret.total);
      // if (ret.records.length === 0) {
      //   spliceSocket('WITHDRAW');
      //   sessionStorage.setItem("WITHDRAW", 0);
      // } else {
      //   sessionStorage.setItem("WITHDRAW", ret.records.length);
      // }
    };

    const checkOutstandingBeforePaid = async() => {
      const query = checkQuery();
      const { data: ret } = await getMemberWithdrawRecordBeforePaid(query);
      if (ret.records.length === 0) {
        spliceSocket('BEFORE_PAID');
        sessionStorage.setItem("BEFORE_PAID", 0);
      } else {
        sessionStorage.setItem("BEFORE_PAID", ret.records.length);
      }
    };

    const checkOutstandingPayment = async() => {
      const query = checkQuery();
      const { data: ret } = await getMemberWithdrawRecordPay(query);
      if (ret.records.length === 0) {
        spliceSocket('PAYMENT');
        sessionStorage.setItem("PAYMENT", 0);
      } else {
        sessionStorage.setItem("PAYMENT", ret.records.length);
      }
    };

    const checkOutstandingFinancialFeedback = async() => {
      const { data: ret } = await getFinanceFeedbackCount();
      if (ret === 0) {
        spliceSocket('FINANCE_FEEDBACK');
        sessionStorage.setItem("FINANCE_FEEDBACK", 0);
      } else {
        sessionStorage.setItem("FINANCE_FEEDBACK", ret);
      }
    };

    const checkGetNewRegisterMember = async() => {
      const siteId = store.state.user.siteId;
      if (isKorea(siteId)) {
        const { data: ret } = await getNewRegisterMemberLists(siteId);
        // console.log(ret);
        if (ret === 0) {
          // sessionStorage.setItem("NEW_REGISTER_USER", 0);
          hasNewUser.value = false
        } else {
          if (notificationAudioRef.value) notificationAudioRef.value.play()
          hasNewUser.value = true
          // sessionStorage.setItem("NEW_REGISTER_USER", ret);
        }
      }
    }

    onMounted(async() => {
      if (!hasPermission(["sys:withdraw:apply"]) && hasPermission(["sys:withdraw:simple:list"])) {
        await checkOutstandingAutoWithdraw();
      }
      if (hasPermission(["sys:withdraw:apply"])) {
        await checkOutstandingWithdraw();
      }
      if (hasPermission(["sys:withdraw:before-paid"])) {
        await checkOutstandingBeforePaid();
      }
      if (hasPermission(["sys:withdraw:pay"])) {
        await checkOutstandingPayment();
      }
      if (hasPermission(["sys:feedback:list"])) {
        await checkOutstandingFinancialFeedback();
      }
      if (hasPermission(["sys:member:list"])) {
        await checkGetNewRegisterMember();
        setInterval(async() => {
          await checkGetNewRegisterMember();
        }, 180000)
      }
      isMounted.value = true;
    });

    // within variables only have those used in the component, what hpn to the other
    return {
      sidebar,
      routes,
      variables,
      activeMenu,
      isCollapse,
      isMounted,
      notificationAudioRef,
      hasNewUser,
      isKorea
    }
  }
})
</script>

<style lang="scss">
.sidebar-container {
  // reset element-ui css
  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out,
      0s padding-right ease-in-out;
  }

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-scrollbar__view {
    height: 100%;
  }

  .el-scrollbar__bar {
    &.is-vertical {
      right: 0px;
    }

    &.is-horizontal {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-scrollbar {
  height: 100%;
}

.has-logo {
  .el-scrollbar {
    height: calc(100vh - 100px);
  }
}

.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
</style>
